.MyList {
  width: 100%;
}

.MyList > a {
  width: 100%;
  height: 84px;
  text-decoration: none;
  color: var(--black);
}

@media (max-width: 767px) {
  .MyList > a {
    height: auto;
  }
}