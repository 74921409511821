.ProgressBar {
  width: 400px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.ProgressBar > .Prog {
  width: 100%;
  /* border-radius: 50px; */
  position: absolute;
  top: 65%;
  height: 25px;
  background-color: #abb2bc;
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #b6bcc6), color-stop(1, #9da5b0));
  background: -moz-linear-gradient(#9da5b0 0%, #b6bcc6 100%);
  -webkit-box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.5), 0px 1px 0px 0px #FFF;
  -moz-box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.5), 0px 1px 0px 0px #FFF;
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.5), 0px 1px 0px 0px #FFF;
}

.ProgressBar > .Prog > div {
  /* height: 100%;
  border-radius: inherit;
  text-align: right;
  transition: width 1s ease-in-out;
  width: 0%; */
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  height: 105%;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  border-radius: 35px;
  -webkit-background-size: 44px 44px;
  background-size: 44px 44px;
  background-color: #026AA4;
  background: -webkit-gradient(linear, 0 0, 44 44,
    color-stop(0.00, rgba(255,255,255,0.17)),
    color-stop(0.25, rgba(255,255,255,0.17)),
    color-stop(0.26, rgba(255,255,255,0)),
    color-stop(0.50, rgba(255,255,255,0)),
    color-stop(0.51, rgba(255,255,255,0.17)),
    color-stop(0.75, rgba(255,255,255,0.17)),
    color-stop(0.76, rgba(255,255,255,0)),
    color-stop(1.00, rgba(255,255,255,0))
  ), -webkit-gradient(linear, left bottom, left top, color-stop(0, #74d04c), color-stop(1, #9bdd62));
  background: -moz-repeating-linear-gradient(top left -30deg,
    rgba(255,255,255,0.17),
    rgba(255,255,255,0.17) 15px,
    rgba(255,255,255,0) 15px,
    rgba(255,255,255,0) 30px
  ), -moz-linear-gradient(#839df3 0%, #839df3 100%);
  animation: animate-stripes 2s linear infinite;
  -webkit-animation: animate-stripes 2s linear infinite;
}

.ProgressBar span {
  position: absolute;
  top: 83%;
  font-size: 22px;
  left: 50%;
  transform: translateX(-25%);
  color: #fff;
  font-weight: bold;
}