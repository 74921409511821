.SubMenu {
  background-color: #fff;
  position: absolute;
  bottom: 10px;
  left: 180px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  width: max-content;
}

.SubMenu a,
.SubMenu p {
  padding: 0.75rem 2rem !important;
}

.SubMenu a:hover,
.SubMenu p:hover {
  background-color: #FAFAFA;
}

@media (max-width: 767px) {
  .SubMenu {
    display: flex;
    flex-direction: column!important;
    bottom: 60px;
    right:5px;
    left: auto;
  }
}