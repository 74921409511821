.FormGroup {
  margin-bottom: 15px;
  min-width: 150px;
}

.FormGroup label span {
  color: #f00;
}

.FormGroup label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

/* Chrome, Safari, Edge, Opera */
.FormGroup input::-webkit-outer-spin-button,
.FormGroup input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.FormGroup input[type=number] {
  -moz-appearance: textfield;
}

.FormGroup input:disabled,
.FormGroup textarea:disabled {
  background: #ddd;
  font-weight: bold;
}

.FormControl {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  box-sizing: border-box;
}

.FormControl:focus {
  outline: none;
  border: 2px solid var(--primary-blue);
}

.FormGroup textarea.FormControl {
  height: auto;
}