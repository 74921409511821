.ChildContainer {
  display: flex;
  justify-content: space-between;
}

.ChildContainer div p {
  color: var(--dark-grey);
  padding-top: 0.3rem;
  font-size: 0.9rem;
}

.ChildContainer i {
  margin-left: 1rem;
}

@media only screen and (max-width: 767px){
  .ChildContainer div {
    line-height: 1rem;
  }

  .ChildContainer h4 {
    line-height: 1.3rem;
    font-size: 18px;
  }
}