.VideoEmbed {
  margin: 1.4rem 0;
  display: flex;
}

.VideoEmbed i {
  margin-right: 1rem;
  font-size: 20px;
  width: 20px;
}

.VideoEmbed p {
  cursor: pointer;
  color: #828282;
  font-size: 16px;
}

.VideoEmbed .InputContainer .InputWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.VideoEmbed .InputContainer .InputWrapper span {
  margin-right: 10px;
  color: #828282;
}

.VideoEmbed .InputContainer .InputWrapper i {
  margin: 0 0 0 1rem !important;
  cursor: pointer;
  font-size: 17px;
  width: auto;
}

.VideoEmbed .InputContainer .InputWrapper input {
  margin: 0 !important;
  padding: 0.25rem !important;
  width: 250px;
}

.VideoEmbed .InputContainer .VideoPreviewContainer {
  display: flex;
  flex-wrap: wrap;
}

.VideoEmbed .InputContainer .VideoPreviewContainer iframe {
  margin: 10px 10px 10px 0;
}

.NotValid {
  outline: 2px solid rgb(156, 4, 4);
}

@media (max-width: 767px) {
  .VideoEmbed .InputContainer .InputWrapper input {
    width: auto;
  }
}