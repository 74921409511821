.SalesItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 23%;
  margin: 2rem 0;
  min-width: 150px;
}

.SalesItem .SaleImageContainer {
  height: 150px;
}

.SalesItem .SaleImageContainer img {
  width: 80%;
  margin: 0 auto;
  max-height: 100%;
}

.SalesItem h4 {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
  min-height: 40px;
}

.SalesItem p {
  text-align: center;
  margin: 0.5rem 0;
  font-size: 0.85rem;
}

.SalesItem button {
  outline: none;
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid var(--primary-blue);
  cursor: pointer;
  width: 100%;
}

.SalesItem .AddToTripBtn {
  background-color: var(--primary-blue);
  color: #fff;
}

.SalesItem .AddToTripBtn:hover {
  background-color: var(--secondary-blue);
}
.SalesItem .RemoveBtn {
  background-color: var(--white);
  color: var(--primary-blue);
}