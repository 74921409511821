.Icon {
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 1.2rem;
}

.Sidebar {
  width: 200px;
  box-sizing: border-box;
  height: 100vh;
  background-color: var(--white);
  position: fixed;
  z-index: 1;
  border-right: 1px solid #f2f2f2;
  padding: 1rem;
}

.Sidebar img {
  width: 75%;
  padding: 1rem 0;
  outline: none;
}

.Sidebar img:active,
.Sidebar a:active {
  transform: scale(0.9, 0.9);
}

.Sidebar div {
  display: flex;
  flex-direction: column;
}

.Sidebar div a,
.Sidebar p {
  padding: 0.75rem 0;
  color: var(--black);
  text-decoration: none;
  font-size: 1rem;
  outline: none;
}

.Sidebar div a:hover,
.Sidebar p:hover {
  color: var(--primary-blue) !important;
}

.Sidebar div .ReqStore {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  cursor: pointer;
  padding: 0.75rem 0;
}

.Sidebar p {
  cursor: pointer;
}

.Sidebar .BtnContainer {
  width: 100%;
}

.Sidebar .BtnContainer button {
  padding: 0.5rem 1.25rem;
  width: 80%;
  margin: 0.5rem 0;
  text-align: center;
  outline: none;
  border: 1px solid var(--primary-blue);
  background-color: var(--white);
  border-radius: 4px;
  cursor: pointer;
}

.Sidebar .BtnContainer button,
.Sidebar .BtnContainer a {
  color: var(--primary-blue);
  font-size: 0.8rem;
}

.Sidebar .BtnContainer button:hover {
  background-color: #f1f1f1;
}

.Sidebar .LoginContainer {
  position: absolute;
  bottom: 50px;
  user-select: none;
  width: 100%;
}

.Sidebar .Icon {
  width: 1.2rem;
  text-align: center;
  font-size: 17px;
}

@media only screen and (max-width: 767px) {
  hr{
    display: none;
  }

  .Sidebar {
    position: fixed;
    bottom: 0;
    height: 70px;
    width: 100%;
    padding: 0;
    box-shadow: 0 -1px 1px rgba(0,0,0,0.12);
    z-index: 1000;
  }

  .Sidebar div {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .Logo {
    display: none!important;
  }
  
  .Sidebar > div > a,
  .Sidebar > div > p,
  .Sidebar > div > div > a, 
  .Sidebar > div > div > p{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 0;
    color: #aaa;
    font-size: 14px;
  }

  /* Request Store Hidden on Mobile */
  .Sidebar div a:nth-child(5) {
    display: none;
  }

  .Sidebar div a i,
  .Sidebar div p i {
    font-size: 22px;
  }

  .Sidebar div a:hover,
  .Sidebar div p:hover {
    color: var(--black) !important;
  }

  .Sidebar div .ReqStore {
    display: none;
  }

  .Sidebar .LoginContainer {
    display: none;
  }

  .Icon {
    color: #aaa!important;
    padding: 0;
    font-size: 1.5rem;
  }
}

@media print {
  .Sidebar {
    display: none;
  }
}