.ExploreContainer {
  width: 60%;
  min-width: 900px;
}

.TabsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Tabs {
  flex-basis: 50%;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e8e8e8;
  text-align: center;
  outline: none;
}

@media(max-width: 1500px){
  .ExploreContainer {
    width: 70%;
    min-width: 600px;
  }

  .TabsContainer {
    min-width: 600px;
  }
}

/* Mobile @media */
@media only screen and (max-width: 767px) {
  .ExploreContainer {
    width: 100%;
    min-width: 200px;
  }

  .TabsContainer {
    min-width: auto;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ExploreContainer {
    min-width: 100%;
  }
}