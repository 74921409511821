.CreateProject {
  width: 50%;
  max-width: 600px;
}

.CreateProject .DataSpinner {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.85);
  /* background-color: #e5e5e5; */
}

.CreateProject .DataSpinner p {
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 10%;
  font-weight: 700;
}