.TabSales {
  width: 100%;
  display: inline-block;
  width: 100%;
}

.TabSales ul {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  justify-content: space-between;
  list-style: none;
}

@media only screen and (max-width: 767px){
  .TabSales {
    overflow: scroll;
  }

  .TabSales::after {
    content: '';
    display: block;
    height: 150px;
  }
}