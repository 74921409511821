.ProjectActions .SecondaryAct > div,
.ProjectActions .PrimaryAct > div {
  background-color: var(--primary-blue);
  margin-bottom: 1rem;
  padding: 15px 30px;
  border-radius: 10px;
  color: var(--white);
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
  width: 124px;
}

.ProjectActions .SecondaryAct > div i,
.ProjectActions .PrimaryAct > div i {
  margin-right: 6px;
  font-size: 18px;
}

.ProjectActions .SecondaryAct > .Selected {
  background-color: var(--white);
  border: 1px solid var(--primary-blue);
}

.ProjectActions .SecondaryAct > .Selected > i,
.ProjectActions .SecondaryAct > .Selected > span {
  color: var(--primary-blue);
}

.ProjectActions .PrimaryAct div:first-child {
  background-color: var(--primary-blue);
}

.ProjectActions .PrimaryAct div:nth-child(2) {
  background-color: #AB2C2C;
}

@media (max-width: 767px) {
  .ProjectActions .SecondaryAct,
  .ProjectActions .PrimaryAct {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1em 0;
  }

  .ProjectActions .SecondaryAct > div,
  .ProjectActions .PrimaryAct > div {
    margin: 0 10px 0 0;
    padding: 1em 2em;
    width: auto;
  }

  .ProjectActions .SecondaryAct > div i,
  .ProjectActions .PrimaryAct > div i  {
    font-size: 12px;
  }
}