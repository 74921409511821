.HeaderTitle  {
  padding: 0 1rem;
  display: block;
  width: calc(70% - 364px);
  min-width: 300px;
}

.HeaderTitle h1 {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 7px;
}

.HeaderTitle p {
  line-height: 24px;
  color: #031016;
  font-size: 14px;
}

.HeaderTitle a {
  font-size: 14px;
  color: var(--primary-blue);
}

.HeaderTitle .MetaTags {
  margin: 10px 0;
}

.HeaderTitle .MetaTags span {
  text-transform: capitalize;
  display: inline-block;
}

.HeaderTitle .MetaTags span {
  border-radius: 100px;
  border: 1px #031016 solid;
  color: #031016;
  padding: 3px 7px;
  margin-right: 10px;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
}

.HeaderTitle .MetaTags span i {
  margin-right: 7px;
}

@media (max-width: 767px) {
  .HeaderTitle {
    min-width: 100%;
    padding: 0;
    margin: 1em 0;
  }
}