.ImageUpload {
  margin: 1.4rem 0;
  display: flex;
}

.ImageUpload i {
  margin-right: 1rem;
  font-size: 20px;
  width: 20px;
}

.ImageUpload div span {
  cursor: pointer;
  color: #828282;
  font-size: 16px;
}

.ImageUpload div input[type=file] {
  font-size: 14px;
}

.ImageUpload div input {
  margin: 0 !important;
}

.ImageUpload .ImagesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ImageUpload .ImagesContainer div {
  margin: 0 1rem 1rem 0;
  position: relative;
}

.ImageUpload .ImagesContainer div img {
  width: 120px;
  height: 90px;
  cursor: pointer;
  z-index: 10;
  box-sizing: border-box;
}

.ImageUpload .ImagesContainer div .CoverImg {
  border: 2px solid var(--primary-blue);
}

.ImageUpload .ImagesContainer div p {
  display: none;
}

.ImageUpload .ImagesContainer div .CoverTxt {
  display: block;
  font-size: 14px;
  line-height: 16.41px;
  text-align: center;
  color: var(--primary-blue);
}

.ImageUpload .ImagesContainer div ul {
  position: absolute;
  top: 90px;
  left: 120px;
  width: 170px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}

.ImageUpload .ImagesContainer div ul li {
  list-style-type: none;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  padding: 10px 20px;
  cursor: pointer;
}

.ImageUpload .ImagesContainer div ul li:hover {
  background-color: #eee;
}

/*  CSS for delete button
Needs to be deleted possibly
.ImageUpload .ImagesContainer div p {
  margin: 0 !important;
  text-align: center;
  font-size: 12px;
  color: #a10f0f;
  cursor: pointer;
}

.ImageUpload .ImagesContainer div p:hover {
  text-decoration: underline;
} */