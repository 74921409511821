.LandingNavbar {
  width: 100%;
  background-color: var(--white);
  position: fixed;
  z-index: 100;
  height: 4rem;
  box-shadow: 0 1px 1px rgba(0,0,0,0.2);
}

.LandingNavbar .LdContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 12.5%;
}

.LandingNavbar .LdContainer .NavbarOptions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.LandingNavbar .LdContainer .NavbarOptions .Options {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.LandingNavbar .LdContainer .NavbarOptions .Options div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.LandingNavbar .LdContainer .NavbarOptions img {
  width: 120px;
  padding: 0.75rem 0;
}

.LandingNavbar .LdContainer .NavbarOptions img:active,
.LandingNavbar .LdContainer .NavbarOptions .Options .MenuOptions a:active {
  transform: scale(0.9, 0.9);
}

.LandingNavbar .LdContainer .NavbarOptions .Options .MenuOptions a {
  margin: 0.5rem 1rem;
  text-transform: uppercase;
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
}

.LandingNavbar .LdContainer .NavbarOptions .Options .LoginBtn {
  text-transform: uppercase;
  border: 2px var(--primary-blue) solid;
  color: var(--primary-blue);
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  border-radius: 7px;
}
.LandingNavbar .LdContainer .NavbarOptions .Options .LoginBtn:hover {
  background-color: #026AA4;
  color: #fff;
  transition: background-color 300ms linear;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: color 300ms linear;
  -webkit-transition: color 300ms linear;
  -ms-transition: color 300ms linear;
}

.LandingNavbar .LdContainer .NavbarOptions .Options div span {
  color: var(--primary-blue);
}

.LandingNavbar .LdContainer .NavbarOptions .Options .LogOutBtn {
  color: #1B77F2;
  margin-left: 0.25rem;
  font-size: 0.9rem;
  text-decoration: underline;
  cursor: pointer;
}

.LandingNavbar .LdContainer .NavbarOptions .Options .LogOutBtn:hover {
  color: var(--primary-blue);
}

@media (min-width: 1500px) {
  .LandingNavbar .LdContainer {
    margin: 0 20%;
  }
}


@media (max-width: 767px){
  .LandingNavbar .LdContainer .NavbarOptions {
    width: 90%;
    margin: 0;
    justify-content: space-between;
    flex-direction: column;
    margin: 0 auto; 
  }

  .LandingNavbar .LdContainer {
    margin: 0;
    background-color: var(--white);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .LandingNavbar .LdContainer .NavbarOptions .Options{
    border-top: 2px solid rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    flex-direction: column;
  }

  .LandingNavbar .LdContainer .NavbarOptions .Options div:last-child {
    justify-content: center;
    margin-bottom: 1rem;
  }

  .LandingNavbar .LdContainer .NavbarOptions .Options .LoginBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .LandingNavbar .LdContainer .NavbarOptions .Options .MenuOptions {
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  .LandingNavbar .LdContainer .NavbarOptions .Options .MenuOptions a {
    margin: 0.5rem 0;
  }
}