.ProjectImages {
  width: 364px;
}

.ProjectImages > .ImageBox {
  width: 364px;
  background-color: var(--black);
  box-sizing: border-box;
  border-radius: 10px;
  height: 242.4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.12);
}

.ProjectImages > .ImageBox > img,
.ProjectImages > iframe {
  max-width: 100%;
  height: 242.4px;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.24);
}

.ProjectImages > .ImageBox > img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ProjectImages > iframe {
  border-radius: 10px;
  width: 364px;
}

.ProjectImages > .OtherImgs {
  display: flex;
}

.ProjectImages > .OtherImgs > img,
.ProjectImages > .OtherImgs > div{
  width: 48px;
  height: 36px;
  margin: 5px 5px 5px 0;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
}

.ProjectImages > .OtherImgs .Focused {
  border: 2px solid var(--primary-blue);
}

.ProjectImages > .OtherImgs img:hover,
.ProjectImages > .OtherImgs > div:hover {
  opacity: 0.8;
}

.ProjectImages > .OtherImgs > div {
  position: relative;
}

.ProjectImages > .OtherImgs > div img {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 10;
}

.ProjectImages > .OtherImgs > div i {
  z-index: 20;
  position: absolute;
  font-size: 30px;
  opacity: 0.7;
  color: #000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

@media (min-width: 1500px){
  .ProjectImages {
    width: 455px;
  }

  .ProjectImages > img,
  .ProjectImages > iframe {
    height: 303px;
  }
}

@media (max-width: 768px) {
  .ProjectImages {
    width: 100%;
  }

  .ProjectImages > .ImageBox {
    width: 100%;
  }

  .ProjectImages > .OtherImgs {
    flex-wrap: wrap;
  }
}