.ReqStore {
  width: 50%;
  max-width: 600px;
}

.ModalHeader {
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.ModalHeader h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.ModalBody {
  padding: 15px 0;
}

.ModalFooter {
  padding: 15px 0;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
}

.ModalFooter button{
  margin-left: 5px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 6px 12px;
  border-radius: 4px;
}

.ModalFooter button:first-child{
  text-shadow: 0 1px 0 #fff;
  background-image: -webkit-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: -o-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}

.ModalFooter button:first-child:hover{
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.ModalFooter button:last-child {
  background-color: var(--primary-blue);
  color: #fff;
}

.ModalFooter button:last-child:hover {
  color: #fff;
  background-color: var(--secondary-blue);
  border-color: var(--secondary-blue);
}

@media (max-width: 1350px) {
  .ReqStore {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .ReqStore {
    width: 100%;
    margin: 10px auto;
  }

  .ReqStore h4 {
    text-align: center;
  }
}