.PastTrips {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
  line-height: 1.7rem;
  cursor: pointer;
  border-bottom: 1px #d6d6d6 solid;
}

.PastTrips p {
  font-size: 0.85rem;
  color: #828282;
}

.TripsDisplay {
  flex-basis: 100%;
  display: flex;
}
