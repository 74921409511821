.TermsBox {
  padding: 5px;
}

.TermsBox > div h3,
.TermsBox > div h4,
.TermsBox > div p {
  margin-bottom: 10px;
  text-align: justify;
}

.TermsBox > div h3 {
  font-size: 19px;
  font-weight: 600;
}

.TermsBox > div h4 {
  font-size: 17px;
  font-weight: 600;
}

.TermsBox > div p {
  font-size: 14px;
}

.TermsBox > div ul,
.TermsBox > div ol {
  margin: 0 0 10px 40px;
  font-size: 14px;
}