.TabsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.Tabs {
  flex-basis: 50%;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e8e8e8;
  text-align: center;
  outline: none;
  cursor: pointer;
}

@media print {
  .TabsContainer {
    display: none;
  }
}