.WebsiteBox {
  margin: 1.4rem 0;
  display: flex;
  width: 100%;
}

.WebsiteBox i {
  margin-right: 1rem;
  font-size: 20px;
  width: 20px;
}

.WebsiteBox div {
  width: 100%;
}


.WebsiteBox div span {
  cursor: pointer;
  color: #828282;
  font-size: 16px;
  min-width: 400px;
  max-width: 100%;
}

.WebsiteBox div input {
  margin: 0 !important;
  padding: 0.25rem !important;
  width: 100%;
}