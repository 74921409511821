.ProjectInstructions {
  margin: 1rem 0 3rem 0;
  width: 60%;
}

.ProjectInstructions h4 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  margin-bottom: 10px;
}

.ProjectInstructions p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.ProjectInstructions > div {
  margin: 1rem 0;
}

.ProjectInstructions > div h1,
.ProjectInstructions > div h2,
.ProjectInstructions > div h3 {
  margin: 0.5rem 0;
  font-size: 14px;
  font-weight: 700;
}

.ProjectInstructions > div ol,
.ProjectInstructions > div ul {
  display: block;
  margin: 0 20px;
}

.ProjectInstructions > div ol li,
.ProjectInstructions > div ul li {
  margin-bottom: 1rem;
}

.ProjectInstructions > div ol li,
.ProjectInstructions > div ul li,
.ProjectInstructions > div p {
  font-size: 14px;
  line-height: 19px;
}

@media (max-width: 1200px) {
  .ProjectInstructions {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .ProjectInstructions {
    width: 100%;
  }

  .ProjectInstructions > div p {
    margin-bottom: 5px;
  }
}