.Acknowlegement {
  height: 600px;
  padding: 10px;
}

.Acknowlegement h2 {
  font-size: 22px;
  padding-bottom: 10px;
  box-sizing: border-box;
  color: var(--primary-blue);
  font-weight: 400;
}

.Acknowlegement .PolicyBox {
  height: 475px;
  overflow: scroll;
  padding: 10px;
  border: 1px var(--black) solid;
}

.Acknowlegement .Checkbox {
  cursor: pointer;
  margin: 10px 0;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.Acknowlegement .Checkbox input {
  cursor: pointer;
}

.Acknowlegement .Checkbox label {
  cursor: pointer;
  display: inline;
  padding-left: 15px;
}

.Acknowlegement .SubmitButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Acknowlegement .SubmitButtons > button {
  margin-left: 5px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 6px 12px;
  border-radius: 4px;
}

.Acknowlegement .SubmitButtons > button:first-child{
  text-shadow: 0 1px 0 #fff;
  background-image: -webkit-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: -o-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}

.Acknowlegement .SubmitButtons > button:first-child:hover{
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.Acknowlegement .SubmitButtons > button:last-child:enabled {
  background-color: var(--primary-blue);
  color: #fff;
}

.Acknowlegement .SubmitButtons > button:last-child:hover:enabled {
  color: #fff;
  background-color: var(--secondary-blue);
  border-color: var(--secondary-blue);
}

@media (max-width: 767px) {
  .Acknowlegement h2 {
    text-align: center;
  }
}