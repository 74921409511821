.SalesItem {
  display: flex;
  justify-content: flex-start;
  align-items: space-between;
  padding: 1rem 2rem 2rem 2rem;
  border-bottom: 2px solid #E4E8EA;
  width: 100%;
  box-sizing: border-box;
}

.SalesItem img {
  width: 7rem;
  height: 100%;
  margin-right: 2rem;
}

.SalesItem div {
  display: flex;
  flex-direction: column;
}

.SalesItem div h4 {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.SalesItem p {
  margin: 0.5rem 0;
  font-size: 0.85rem;
}

.SalesItem div button {
  outline: none;
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid #016BA4;
  cursor: pointer;
  width: 100px;
}

.SalesItem div .AddToTripBtn {
  background-color: #016BA4;
  color: #fff;
}

.SalesItem div .RemoveBtn {
  background-color: #fff;
  color: #016BA4;
}

/*
.SalesItem h4 {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
}

.SalesItem p {
  text-align: center;
  margin: 0.5rem 0;
  font-size: 0.85rem;
}

.SalesItem button {
  outline: none;
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid #016BA4;
  cursor: pointer;
  width: 100%;
}

.SalesItem .AddToTripBtn {
  background-color: #016BA4;
  color: #fff;
}

.SalesItem .RemoveBtn {
  background-color: #fff;
  color: #016BA4;
}
 */
