.StoreMap {
  width: calc(100% - 700px);
  background: #fff;
  height: auto;
  position: fixed;
  margin-left: 550px;
}

.StoreMap .FloorController {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  list-style-type: none;
  transition: opacity 0.3s;
  z-index: 100;
}

.StoreMap .FloorController:hover {
  opacity: 1!important;
}

.StoreMap .FloorController > li {
  background-color: #fff;
  border: none;
  outline: none;
  padding: 16px;
  cursor: pointer;
  color: #026AA4;
  font-size: 14px;
  user-select: none;
  transition: transform .18s ease-out;
}

.StoreMap .FloorController > li:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.StoreMap .FloorController > li:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.StoreMap .FloorController > li.CurrentFloor {
  background-color: #E4F0F6;
}

.StoreMap .ScrollContainer {
  height: 100vh;
}

.Tools {
  position: absolute;
  /* padding: 70px 0 0 10px; */
  top: 60px;
  right: 10px;
  z-index: 19;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px rgba(0,0,0,0.3);
  border-radius: 8px;
  background: #fff;
}

.Tools > div {
  padding: 8px;
}

.Tools button {
  width: 25px;
  height: 25px;
}

.StoreMap .Map{
  width: 80%;
  height: 100vh;
}

.Map canvas{
  padding: 1rem 2rem 2rem 2rem;
  box-sizing: border-box;
}

.StoreMap .Layout button{
  margin: 0 1rem;
  padding: 0.2rem;
  background: transparent;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 1s;
}

.Active {
  border: 2px outset rgb(118, 118, 118) !important;
}

/* @media (max-width: 1400px) {
  .StoreMap {
    margin-left: 1000px;
    width: 100vw;
  }
} */

@media only screen and (max-device-width: 767px) {
  .StoreMap {
    position: absolute;
    top: 70px;
    width: 100%;
    left: 0;
    margin: 0;
    height: auto !important;
  }

  .StoreMap .FloorController {
    bottom:30px;
    right: 10px;
    left: auto;
    top: auto;
    transform: translateY(-50%);
  }

  .StoreMap .ScrollContainer {
    overflow-y: scroll;
    height: calc(100vh - 70px - 60px - 50px) !important;
  }
  
  .StoreMap .Map {
    box-sizing: border-box;
    margin: 4rem 0;
    height: calc(100vh - 70px - 60px - 50px) !important;
  }

  .StoreMap canvas {
    box-sizing: border-box;
  }
}

@media print {
  @page {size: landscape}
  .StoreMap {
    width: 100%;
    page-break-before: always;
    break-before: always;
    order: 1;
  }

  .StoreMap canvas {
    width: 100vw !important;
    height: auto !important;
    padding: 0 !important;
  }
}