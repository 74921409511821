.SalesStore {
  width: 60%;
}

.SalesStore .Header {
  margin: 2rem 0;
}

.SalesStore .Header h3 {
  font-weight: 500;
  font-size: 1.4rem;
}

.SalesStore .Header p {
  margin: 0.5rem 0;
}

.SalesStore .Header a {
  background-color: var(--primary-blue);
}

.SalesStore .Header a:hover {
  background-color: var(--secondary-blue);
}

.SalesStore .Header button,
.SalesStore .Header a {
  padding: 0.75rem 1rem;
  margin: 0 0.3rem 0 0;
  font-size: 0.75rem;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: var(--white);
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
  cursor: pointer;
}

.SalesStore .Header .AddTrip {
  background-color: var(--primary-blue);
}

.SalesStore .Header .AddTrip:hover {
  background-color: var(--secondary-blue);
}

.SalesStore .Header .UpdateTrip {
  background-color: #17b617;
}

.SalesStore .Header .UpdateTrip:hover {
  background-color: #43ac6a;
}

.SalesStore .SalesContainer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  justify-content: space-between;
  list-style: none;
}

@media (max-width: 1200px) {
  .SalesStore {
    width: 80%;
  }
}