.ProjectDetails {
  width: calc(100vw - 300px);
}

/* @media(min-width: 1500px){
  .ProjectDetails {
    width: 40%;
  }
} */

@media (max-width: 768px) {
  .ProjectDetails {
    width: 100%;
  }
}