@import url(https://fonts.googleapis.com/css2?family=Roboto &display=swap);
.SubMenu_SubMenu__1ssuA {
  background-color: #fff;
  position: absolute;
  bottom: 10px;
  left: 180px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.SubMenu_SubMenu__1ssuA a,
.SubMenu_SubMenu__1ssuA p {
  padding: 0.75rem 2rem !important;
}

.SubMenu_SubMenu__1ssuA a:hover,
.SubMenu_SubMenu__1ssuA p:hover {
  background-color: #FAFAFA;
}

@media (max-width: 767px) {
  .SubMenu_SubMenu__1ssuA {
    display: flex;
    flex-direction: column!important;
    bottom: 60px;
    right:5px;
    left: auto;
  }
}
.Sidebar_Icon__FUuSK {
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 1.2rem;
}

.Sidebar_Sidebar__27mHj {
  width: 200px;
  box-sizing: border-box;
  height: 100vh;
  background-color: var(--white);
  position: fixed;
  z-index: 1;
  border-right: 1px solid #f2f2f2;
  padding: 1rem;
}

.Sidebar_Sidebar__27mHj img {
  width: 75%;
  padding: 1rem 0;
  outline: none;
}

.Sidebar_Sidebar__27mHj img:active,
.Sidebar_Sidebar__27mHj a:active {
  transform: scale(0.9, 0.9);
}

.Sidebar_Sidebar__27mHj div {
  display: flex;
  flex-direction: column;
}

.Sidebar_Sidebar__27mHj div a,
.Sidebar_Sidebar__27mHj p {
  padding: 0.75rem 0;
  color: var(--black);
  text-decoration: none;
  font-size: 1rem;
  outline: none;
}

.Sidebar_Sidebar__27mHj div a:hover,
.Sidebar_Sidebar__27mHj p:hover {
  color: var(--primary-blue) !important;
}

.Sidebar_Sidebar__27mHj div .Sidebar_ReqStore__2tO6v {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  cursor: pointer;
  padding: 0.75rem 0;
}

.Sidebar_Sidebar__27mHj p {
  cursor: pointer;
}

.Sidebar_Sidebar__27mHj .Sidebar_BtnContainer__QuBPe {
  width: 100%;
}

.Sidebar_Sidebar__27mHj .Sidebar_BtnContainer__QuBPe button {
  padding: 0.5rem 1.25rem;
  width: 80%;
  margin: 0.5rem 0;
  text-align: center;
  outline: none;
  border: 1px solid var(--primary-blue);
  background-color: var(--white);
  border-radius: 4px;
  cursor: pointer;
}

.Sidebar_Sidebar__27mHj .Sidebar_BtnContainer__QuBPe button,
.Sidebar_Sidebar__27mHj .Sidebar_BtnContainer__QuBPe a {
  color: var(--primary-blue);
  font-size: 0.8rem;
}

.Sidebar_Sidebar__27mHj .Sidebar_BtnContainer__QuBPe button:hover {
  background-color: #f1f1f1;
}

.Sidebar_Sidebar__27mHj .Sidebar_LoginContainer__uidqi {
  position: absolute;
  bottom: 50px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  width: 100%;
}

.Sidebar_Sidebar__27mHj .Sidebar_Icon__FUuSK {
  width: 1.2rem;
  text-align: center;
  font-size: 17px;
}

@media only screen and (max-width: 767px) {
  hr{
    display: none;
  }

  .Sidebar_Sidebar__27mHj {
    position: fixed;
    bottom: 0;
    height: 70px;
    width: 100%;
    padding: 0;
    box-shadow: 0 -1px 1px rgba(0,0,0,0.12);
    z-index: 1000;
  }

  .Sidebar_Sidebar__27mHj div {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
  }

  .Sidebar_Logo__dwZL1 {
    display: none!important;
  }
  
  .Sidebar_Sidebar__27mHj > div > a,
  .Sidebar_Sidebar__27mHj > div > p,
  .Sidebar_Sidebar__27mHj > div > div > a, 
  .Sidebar_Sidebar__27mHj > div > div > p{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 0;
    color: #aaa;
    font-size: 14px;
  }

  /* Request Store Hidden on Mobile */
  .Sidebar_Sidebar__27mHj div a:nth-child(5) {
    display: none;
  }

  .Sidebar_Sidebar__27mHj div a i,
  .Sidebar_Sidebar__27mHj div p i {
    font-size: 22px;
  }

  .Sidebar_Sidebar__27mHj div a:hover,
  .Sidebar_Sidebar__27mHj div p:hover {
    color: var(--black) !important;
  }

  .Sidebar_Sidebar__27mHj div .Sidebar_ReqStore__2tO6v {
    display: none;
  }

  .Sidebar_Sidebar__27mHj .Sidebar_LoginContainer__uidqi {
    display: none;
  }

  .Sidebar_Icon__FUuSK {
    color: #aaa!important;
    padding: 0;
    font-size: 1.5rem;
  }
}

@media print {
  .Sidebar_Sidebar__27mHj {
    display: none;
  }
}
.shopping-list {
  border-collapse: collapse;
  display: table;
  width: calc(100% + 30px);
  margin-left: -30px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.shopping-list th {
  text-align: start;
  text-transform: uppercase;
  color: #798B95;
  font-weight: 300;
}

.shopping-list thead .shopping-list-header,
.shopping-list tbody #shopping-list-aisle,
.shopping-list tbody #shopping-list-level {
  text-align: center;
}

.shopping-list tbody tr.shopping-list-focus {
  background-color: #FBEFD3;
  box-shadow: 0 0 3px #000;
}

.shopping-list tbody tr td {
  padding: 0.5rem 0;
  cursor: pointer;
  position: relative;
}

.shopping-list tbody tr td.shopping-list-strikeout::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 2px solid #111;
  width: 100%;
}

.shopping-list tbody tr td i {
  font-size: 18px;
}

/* Number Bullets */
.shopping-list tbody td:nth-child(1) > span {
  width: 20px;
  height: 20px;
  background-color: #1B3E59;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin: 0 5px;
}

/* Item Name */
.shopping-list tbody td:nth-child(2) { max-width: 125px; }

/* Aisle Number */
.shopping-list tbody #shopping-list-aisle { max-width: 75px; }

/* Category Color */
.shopping-list tbody #shopping-list-category > span {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
}

/* Trash */
.shopping-list tbody #shopping-list-trash {
  color: #f00;
}

.fas.fa-check-circle {
  color: #00831c;
}

@media (max-device-width: 767px) {
  .shopping-list {
    margin: 0;
    width: 100%;
    margin-top: 2em;
    font-size: 13px;
    table-layout: fixed;
    display: block;
  }

  .shopping-list thead {
    margin-bottom: 0.5rem;
  }

  .shopping-list tbody {
    display: inline-block;
    overflow: scroll;
    box-sizing: border-box;
  }

  .shopping-list tbody::after {
    content: '';
    display: block;
    height: 150px;
  }

  .shopping-list thead,
  .shopping-list tbody tr {
    display:table;
    width:100vw;
    table-layout:fixed;
  }
}
.TypeAhead_TypeAheadDropDown__1Vil9{
  width: 100%;
  box-shadow: 0 0 1px rgba(0,0,0,0.1), 0 2px 4px 1px rgba(0,0,0, .18);
  font-size: 14px;
  color: rgba(0, 0, 0, 0.73);
  box-sizing: border-box;
}
  
.TypeAhead_TypeAheadDropDown__1Vil9 input{
  width: 100%;
  box-sizing: border-box;
  border: none;
  background-color: #f1f1f1;
  resize: none;
  outline: none;
  height: 3rem;
  padding: 0.5rem 0.7rem;
  opacity: 0.7;
  font-size: 1.1rem;
}

.TypeAhead_TypeAheadDropDown__1Vil9 input:focus {
  background: #fff;
}

.TypeAhead_TypeAheadDropDown__1Vil9 i {
  cursor: pointer;
}

.TypeAhead_TypeAheadDropDown__1Vil9 input:focus + i {
  background-color: #E4F0F6;
  color: #346BA4;;
}

.TypeAhead_TypeAheadDropDown__1Vil9 i {
  background: #f1f1f1;
  box-sizing: border-box;
  font-size: 1rem;
  padding: 1rem;
  opacity: 0.7;
}

.TypeAhead_TypeAheadDropDown__1Vil9 ul::before{
  content: '';
}
  
.TypeAhead_TypeAheadDropDown__1Vil9 ul{
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: absolute;
  background: #fff;
  z-index: 10;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  max-height: 400px;
  overflow-y: auto;
}
  
.TypeAhead_TypeAheadDropDown__1Vil9 li{
  padding: 0.8rem 0.7rem;
  cursor: pointer;
}
  
.TypeAhead_TypeAheadDropDown__1Vil9 li:hover{
  background: #f1f1f1;
}

.TypeAhead_Active__bxfHJ {
  background-color: #f1f1f1;
}

@media (min-width: 767px) {
  .TypeAhead_TypeAheadDropDown__1Vil9 {
    position: relative;
  }
}

@media only screen and (max-device-width: 767px){
  .TypeAhead_TypeAheadDropDown__1Vil9 {
    width: 80%;
    border-radius: 12px;
    overflow: hidden;
  }
}

@media print {
  .TypeAhead_TypeAheadDropDown__1Vil9 {
    display: none;
  }
}
.QuickAdd_QuickAdd__1-54A {
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
}

.QuickAdd_QuickAdd__1-54A .QuickAdd_CloseBtn__3cIh6 {
  position: absolute;
  right: 1rem;
  cursor: pointer;
  display: none;
}

.QuickAdd_QuickAdd__1-54A .QuickAdd_CloseBtn__3cIh6:after{
  content: "\00d7"; /* This will render the 'X' */
  font-size: 2rem;
}

.QuickAdd_QuickAdd__1-54A:hover .QuickAdd_CloseBtn__3cIh6 {
  display: block;
}

.QuickAdd_QuickAdd__1-54A h4 {
  display: flex;
  align-items: center;
}

.QuickAdd_QuickAdd__1-54A h4 span {
  width:0;
  height:0;
  border-left: 6px solid var(--black);
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  transition: transform 0.25s ease-in-out;
  margin-left: 0.5rem;
  cursor: pointer;
}

.QuickAdd_QuickAdd__1-54A h4 span:hover {
  border-left: 8px solid var(--secondary-blue);
}

span.QuickAdd_Rotated__2yTjm{
  transform : rotate(90deg); 
}

.QuickAdd_QuickAdd__1-54A .QuickAdd_Controls__1m0GS {
  display: flex;
}

.QuickAdd_QuickAdd__1-54A .QuickAdd_Controls__1m0GS p {
  font-size: 0.8rem;
  text-decoration: underline;
  color: #346BA4;
  cursor: pointer;
  margin-right: 0.75rem;
}

.QuickAdd_QuickAdd__1-54A .QuickAdd_AddInput__2oM7L {
  display: flex;
  flex-direction: row;
}

.QuickAdd_QuickAdd__1-54A .QuickAdd_AddInput__2oM7L button {
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  height: 24px;
}

.QuickAdd_ItemContainer__3Yfld {
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
}

.QuickAdd_ItemContainer__3Yfld i {
  margin-right: 1rem;
}

.QuickAdd_ItemContainer__3Yfld .QuickAdd_RemoveIcon__1c1lg {
  font-size: 0.8rem;
  color: #f00;
}

.QuickAdd_ItemContainer__3Yfld .QuickAdd_RemoveIcon__1c1lg + p {
  font-size: 1rem;
  color: #f00;
}

.QuickAdd_ItemContainer__3Yfld:hover .QuickAdd_RemoveIcon__1c1lg {
  font-size: 1rem;
}

.QuickAdd_ItemContainer__3Yfld p {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

@media print {
  .QuickAdd_QuickAdd__1-54A {
    display: none;
  }
}
.RenderSales_SalesItem__SXBus {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 23%;
  margin: 2rem 0;
  min-width: 150px;
}

.RenderSales_SalesItem__SXBus .RenderSales_SaleImageContainer__scOOT {
  height: 150px;
}

.RenderSales_SalesItem__SXBus .RenderSales_SaleImageContainer__scOOT img {
  width: 80%;
  margin: 0 auto;
  max-height: 100%;
}

.RenderSales_SalesItem__SXBus h4 {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
  min-height: 40px;
}

.RenderSales_SalesItem__SXBus p {
  text-align: center;
  margin: 0.5rem 0;
  font-size: 0.85rem;
}

.RenderSales_SalesItem__SXBus button {
  outline: none;
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid var(--primary-blue);
  cursor: pointer;
  width: 100%;
}

.RenderSales_SalesItem__SXBus .RenderSales_AddToTripBtn__2w1P5 {
  background-color: var(--primary-blue);
  color: #fff;
}

.RenderSales_SalesItem__SXBus .RenderSales_AddToTripBtn__2w1P5:hover {
  background-color: var(--secondary-blue);
}
.RenderSales_SalesItem__SXBus .RenderSales_RemoveBtn__2XBjR {
  background-color: var(--white);
  color: var(--primary-blue);
}
.RenderMobileSales_SalesItem__xDPny {
  display: flex;
  justify-content: flex-start;
  align-items: space-between;
  padding: 1rem 2rem 2rem 2rem;
  border-bottom: 2px solid #E4E8EA;
  width: 100%;
  box-sizing: border-box;
}

.RenderMobileSales_SalesItem__xDPny img {
  width: 7rem;
  height: 100%;
  margin-right: 2rem;
}

.RenderMobileSales_SalesItem__xDPny div {
  display: flex;
  flex-direction: column;
}

.RenderMobileSales_SalesItem__xDPny div h4 {
  margin-top: 0.5rem;
  font-size: 1rem;
}

.RenderMobileSales_SalesItem__xDPny p {
  margin: 0.5rem 0;
  font-size: 0.85rem;
}

.RenderMobileSales_SalesItem__xDPny div button {
  outline: none;
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid #016BA4;
  cursor: pointer;
  width: 100px;
}

.RenderMobileSales_SalesItem__xDPny div .RenderMobileSales_AddToTripBtn__KBNTb {
  background-color: #016BA4;
  color: #fff;
}

.RenderMobileSales_SalesItem__xDPny div .RenderMobileSales_RemoveBtn__1CsPQ {
  background-color: #fff;
  color: #016BA4;
}

/*
.SalesItem h4 {
  margin-top: 0.5rem;
  font-size: 1rem;
  text-align: center;
}

.SalesItem p {
  text-align: center;
  margin: 0.5rem 0;
  font-size: 0.85rem;
}

.SalesItem button {
  outline: none;
  padding: 0.5rem;
  border-radius: 10px;
  border: 1px solid #016BA4;
  cursor: pointer;
  width: 100%;
}

.SalesItem .AddToTripBtn {
  background-color: #016BA4;
  color: #fff;
}

.SalesItem .RemoveBtn {
  background-color: #fff;
  color: #016BA4;
}
 */

.TabSales_TabSales__1g3fD {
  width: 100%;
  display: inline-block;
  width: 100%;
}

.TabSales_TabSales__1g3fD ul {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  justify-content: space-between;
  list-style: none;
}

@media only screen and (max-width: 767px){
  .TabSales_TabSales__1g3fD {
    overflow: scroll;
  }

  .TabSales_TabSales__1g3fD::after {
    content: '';
    display: block;
    height: 150px;
  }
}
.TabsContainer_TabsContainer__1Ag0Y {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.TabsContainer_Tabs__2wKBA {
  flex-basis: 50%;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e8e8e8;
  text-align: center;
  outline: none;
  cursor: pointer;
}

@media print {
  .TabsContainer_TabsContainer__1Ag0Y {
    display: none;
  }
}
.StoreHeader_StoreHeader__VyT0s {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  position: absolute;
  width: 100%;
  z-index: 10;
  background: #fff;
  top: 30px;
  height: 70px;
  box-sizing: border-box;
}

.StoreHeader_StoreHeader__VyT0s .StoreHeader_CloseBtn__33x3- {
  width: 10%;
  padding-left: 10px;
  box-sizing: border-box;
  text-decoration: none;
  color: #000;
}

.StoreHeader_StoreHeader__VyT0s .StoreHeader_CloseBtn__33x3-:after {
  content: "\00d7"; /* This will render the 'X' */
  font-size: 2rem;
}

.StoreHeader_StoreHeader__VyT0s p {
  width: 65%;
  text-align: center;
}

.StoreHeader_StoreHeader__VyT0s .StoreHeader_AddToTrip__35gvW {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 25%;
  color: #016BA4;
}

.StoreHeader_StoreHeader__VyT0s .StoreHeader_AddToTrip__35gvW p {
  width: 100%;
  text-align: center;
}
.StoreMap_StoreMap__1sKxn {
  width: calc(100% - 700px);
  background: #fff;
  height: auto;
  position: fixed;
  margin-left: 550px;
}

.StoreMap_StoreMap__1sKxn .StoreMap_FloorController__19BJM {
  position: absolute;
  top: 50%;
  left: 30px;
  transform: translateY(-50%);
  -webkit-filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  list-style-type: none;
  transition: opacity 0.3s;
  z-index: 100;
}

.StoreMap_StoreMap__1sKxn .StoreMap_FloorController__19BJM:hover {
  opacity: 1!important;
}

.StoreMap_StoreMap__1sKxn .StoreMap_FloorController__19BJM > li {
  background-color: #fff;
  border: none;
  outline: none;
  padding: 16px;
  cursor: pointer;
  color: #026AA4;
  font-size: 14px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  transition: transform .18s ease-out;
}

.StoreMap_StoreMap__1sKxn .StoreMap_FloorController__19BJM > li:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.StoreMap_StoreMap__1sKxn .StoreMap_FloorController__19BJM > li:last-child {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.StoreMap_StoreMap__1sKxn .StoreMap_FloorController__19BJM > li.StoreMap_CurrentFloor__3AX9o {
  background-color: #E4F0F6;
}

.StoreMap_StoreMap__1sKxn .StoreMap_ScrollContainer__1QJCW {
  height: 100vh;
}

.StoreMap_Tools__3jm02 {
  position: absolute;
  /* padding: 70px 0 0 10px; */
  top: 60px;
  right: 10px;
  z-index: 19;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 1px 4px rgba(0,0,0,0.3);
  border-radius: 8px;
  background: #fff;
}

.StoreMap_Tools__3jm02 > div {
  padding: 8px;
}

.StoreMap_Tools__3jm02 button {
  width: 25px;
  height: 25px;
}

.StoreMap_StoreMap__1sKxn .StoreMap_Map__SfODc{
  width: 80%;
  height: 100vh;
}

.StoreMap_Map__SfODc canvas{
  padding: 1rem 2rem 2rem 2rem;
  box-sizing: border-box;
}

.StoreMap_StoreMap__1sKxn .StoreMap_Layout__2DCey button{
  margin: 0 1rem;
  padding: 0.2rem;
  background: transparent;
  border-radius: 10px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: all 1s;
}

.StoreMap_Active__1p8io {
  border: 2px outset rgb(118, 118, 118) !important;
}

/* @media (max-width: 1400px) {
  .StoreMap {
    margin-left: 1000px;
    width: 100vw;
  }
} */

@media only screen and (max-device-width: 767px) {
  .StoreMap_StoreMap__1sKxn {
    position: absolute;
    top: 70px;
    width: 100%;
    left: 0;
    margin: 0;
    height: auto !important;
  }

  .StoreMap_StoreMap__1sKxn .StoreMap_FloorController__19BJM {
    bottom:30px;
    right: 10px;
    left: auto;
    top: auto;
    transform: translateY(-50%);
  }

  .StoreMap_StoreMap__1sKxn .StoreMap_ScrollContainer__1QJCW {
    overflow-y: scroll;
    height: calc(100vh - 70px - 60px - 50px) !important;
  }
  
  .StoreMap_StoreMap__1sKxn .StoreMap_Map__SfODc {
    box-sizing: border-box;
    margin: 4rem 0;
    height: calc(100vh - 70px - 60px - 50px) !important;
  }

  .StoreMap_StoreMap__1sKxn canvas {
    box-sizing: border-box;
  }
}

@media print {
  @page {size: landscape}
  .StoreMap_StoreMap__1sKxn {
    width: 100%;
    page-break-before: always;
    page-break-before: always;
    -webkit-column-break-before: always;
            break-before: always;
    order: 1;
  }

  .StoreMap_StoreMap__1sKxn canvas {
    width: 100vw !important;
    height: auto !important;
    padding: 0 !important;
  }
}
.Draggable_Draggable__2bUL9 {
  width: 100%;
  min-height: 100vh;
  cursor: pointer;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -3px 8px 0px rgba(156,156,156,0.59);
  padding: 10px;
  position: fixed;
  z-index: 20;
  touch-action: none;
  box-sizing: border-box;
}

.Draggable_Draggable__2bUL9 .Draggable_DragDiv__1fkAU {
  height: 50px;
}

.Draggable_Draggable__2bUL9 .Draggable_DragDiv__1fkAU div {
  height: 8px;
  border: 4px #eee solid;
  width: 75px;
  margin: 0 auto;
  border-radius: 8px;
}

@media only screen and (max-width: 767px){
  .Draggable_Draggable__2bUL9 form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
  }

  .Draggable_Draggable__2bUL9 form p {
    outline: none;
    border: none;
    width: 18%;
    text-align: center;
    color: #1C6EE7;
    font-size: 1.2rem;
    box-sizing: border-box;
    background-color: #fff;
  }
}
.StoreTabs_StoreTabs__3_3bQ {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100px;
  z-index: 18;
}

.StoreTabs_Tabs__2YGf1 {
  flex-basis: 50%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #e8e8e8;
  background-color: #fff;
  text-align: center;
  outline: none;
}
.StoreItem_StoreItem__1-0Ao {
  padding: 5rem 3rem;
  width: 550px;
  border-right: 1px solid #efefef;
  box-sizing: border-box;
}

.StoreItem_StoreItem__1-0Ao .StoreItem_Header__3mAju {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.StoreItem_StoreItem__1-0Ao .StoreItem_Header__3mAju button {
  margin: 0 0.3rem 0 0;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #fff;
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
  cursor: pointer;
  height: 50px;
  width: 120px;
}

.StoreItem_StoreItem__1-0Ao .StoreItem_Header__3mAju .StoreItem_AddTrip__jJSUw {
  background-color: var(--primary-blue)
}

.StoreItem_StoreItem__1-0Ao .StoreItem_Header__3mAju .StoreItem_AddTrip__jJSUw:hover {
  background-color: var(--secondary-blue)
}

.StoreItem_StoreItem__1-0Ao .StoreItem_Header__3mAju .StoreItem_UpdateTrip__3mzW3 {
  background-color: #17b617;
}

.StoreItem_StoreItem__1-0Ao .StoreItem_Header__3mAju .StoreItem_UpdateTrip__3mzW3:hover {
  background-color: #43ac6a;
}

.StoreItem_StoreItem__1-0Ao h3{
  margin-bottom: 1rem;
}

.StoreItem_StoreItem__1-0Ao h5 {
  font-weight: normal;
  font-size: 0.9rem;
  color: var(--dark-grey);
  letter-spacing: normal;
}

.StoreItem_StoreItem__1-0Ao form {
  margin-bottom: 2rem;
}

/* @media(max-width: 1400px){
  .StoreItem .Header button {
    padding: 1rem 1rem;
  }
} */

@media (max-device-width: 767px) {
  .StoreItem_Report__3N-7k {
    position: absolute;
    bottom: 110px;
    z-index: 1000;
    right: 10px;
    background: #026AA4;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .StoreItem_Report__3N-7k i {
    font-size: 25px;
    position: absolute;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media print{
  .StoreItem_StoreItem__1-0Ao {
    width: 100%;
    order: 2;
  }

  .StoreItem_StoreItem__1-0Ao a,
  .StoreItem_StoreItem__1-0Ao button {
    display: none;
  }
}
.Store_Store__38uk8 {
  display: flex;
}

@media only screen and (max-width: 767px) {
  .Store_Store__38uk8 {
    margin: 0;
  }
}
.CurrentTrips_CurrentTrips__1KmZE {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.CurrentTrips_CurrentTrips__1KmZE .CurrentTrips_TripsContainer__2qRGk {
  margin-bottom: 2rem;
  width: 100%;
}

.CurrentTrips_TripBox__1PBA1 {
  margin-bottom: 7rem;
}

.CurrentTrips_CurrentTrips__1KmZE .CurrentTrips_TripsContainer__2qRGk .CurrentTrips_Trips__FmX4E {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px #d6d6d6 solid;
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}

.CurrentTrips_CurrentTrips__1KmZE .CurrentTrips_TripsContainer__2qRGk .CurrentTrips_Trips__FmX4E:hover {
  background-color: #fdf7e7;
}

.CurrentTrips_Trips__FmX4E .CurrentTrips_TripDetailsArchive__3-EZR,
.CurrentTrips_Trips__FmX4E .CurrentTrips_TripDetails__1iLQ5 {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.CurrentTrips_TripDetails__1iLQ5 {
  flex-basis: 70%;
}

.CurrentTrips_TripDetailsArchive__3-EZR {
  flex-basis: 100%;
}


.CurrentTrips_Trips__FmX4E .CurrentTrips_TripDetails__1iLQ5 img,
.CurrentTrips_Trips__FmX4E .CurrentTrips_TripDetailsArchive__3-EZR  img {
  width: 75px;
  max-height: 45px;
  padding-right: 1rem;
}

.CurrentTrips_Trips__FmX4E .CurrentTrips_TripDetails__1iLQ5 span,
.CurrentTrips_Trips__FmX4E .CurrentTrips_TripDetailsArchive__3-EZR span {
  color: var(--dark-grey);
  font-size: 0.85rem;
}

.CurrentTrips_Trips__FmX4E .CurrentTrips_TripDetails__1iLQ5 p,
.CurrentTrips_Trips__FmX4E .CurrentTrips_TripDetailsArchive__3-EZR p {
  margin-top: 0.5rem;
  font-size: 0.85rem;
}

.CurrentTrips_Trips__FmX4E button {
  color: #AB2C2C;
  outline: none;
  border: 1px #AB2C2C solid;
  background: #FAE8E8;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  cursor: pointer;
  display: none;
  transition: all 0.2s;
}

.CurrentTrips_Trips__FmX4E button:hover {
  background: #AB2C2C;
  color: #fff;
  border: 1px #000 solid;
  padding: 0.7rem 1.2rem;
  border-radius: 20px;
}

.CurrentTrips_Trips__FmX4E:hover button {
  display: block;
}

.CurrentTrips_CurrentTrips__1KmZE .CurrentTrips_AddStore__3-eqQ {
  background-color: #346BA4;
  color: #fff !important;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-sizing: border-box;
  font-weight: 300;
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
}

.CurrentTrips_CurrentTrips__1KmZE .CurrentTrips_AddStore__3-eqQ:hover {
  background-color: rgb(67, 129, 196);
}

.CurrentTrips_NoTrips__l6lfx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.CurrentTrips_NoTrips__l6lfx p:first-child {
  margin-top: 3rem;
}

.CurrentTrips_NoTrips__l6lfx p {
  margin-top: 1rem
}

/* Mobile @media */
@media only screen and (max-width: 767px) {
  .CurrentTrips_AddStore__3-eqQ {
    position: absolute;
    bottom: 10%;
    width: 90%;
  }

  .CurrentTrips_NoTrips__l6lfx p:first-child {
    margin-top: 6rem;
  }

  .CurrentTrips_Trips__FmX4E button {
    padding: 0.5rem 0.5rem;
  }

  .CurrentTrips_Trips__FmX4E button:hover {
    color: #AB2C2C;
    outline: none;
    border: 1px #AB2C2C solid;
    background: #FAE8E8;
    padding: 0.5rem 1rem;
    border-radius: 15px;
  }

  .CurrentTrips_Trips__FmX4E button:active {
    display: block;
  }

  .CurrentTrips_Trips__FmX4E .CurrentTrips_TripDetails__1iLQ5 {
    flex-basis: 60%;
  }
}
.Controls_ControlsContainer__14DHF {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0 2rem 0;
}

.Controls_ControlsContainer__14DHF .Controls_Date__3jvPq {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.Controls_ControlsContainer__14DHF .Controls_Date__3jvPq input {
  padding: 0.25rem;
}

.Controls_ControlsContainer__14DHF .Controls_Date__3jvPq i {
  cursor: pointer;
  margin-left: 1rem;  
}

.Controls_ControlsContainer__14DHF .Controls_Controls__23S6I {
  display: flex;
  justify-content: flex-end;
}

.Controls_ControlsContainer__14DHF .Controls_Controls__23S6I div {
  cursor: pointer;
  display: flex;
  margin-left: 2rem;
}

.Controls_ControlsContainer__14DHF .Controls_Controls__23S6I p {
  margin-left: 0.5rem;
}

@media only screen and (max-width: 767px) {
  .Controls_ControlsContainer__14DHF {
    flex-direction: column;
  }

  .Controls_ControlsContainer__14DHF .Controls_Date__3jvPq {
    margin-bottom: 1rem;
  }

  .Controls_ControlsContainer__14DHF .Controls_Controls__23S6I {
    width: 100%;
    justify-content: space-between;
  }

  .Controls_ControlsContainer__14DHF .Controls_Controls__23S6I div {
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Controls_ControlsContainer__14DHF .Controls_Date__3jvPq h3 {
    font-size: 1rem;
  }

  .Controls_ControlsContainer__14DHF .Controls_Controls__23S6I p {
    font-size: 0.8rem;
  }
}
.PastTrips_PastTrips__-Bdn2 {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  background: #fff;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
  line-height: 1.7rem;
  cursor: pointer;
  border-bottom: 1px #d6d6d6 solid;
}

.PastTrips_PastTrips__-Bdn2 p {
  font-size: 0.85rem;
  color: #828282;
}

.PastTrips_TripsDisplay__DjjDR {
  flex-basis: 100%;
  display: flex;
}

.TripsContainer_TripsContainer__1W5G2 {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
}

.TripsContainer_MyTrip__15brh {
  width: 60%;
  min-width: 600px;
}

.TripsContainer_TabsContainer__PpNjJ {
  display: flex;
  justify-content: center;
  align-items: center;
}

.TripsContainer_Tabs__1rfHb {
  flex-basis: 50%;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e8e8e8;
  text-align: center;
  outline: none;
}

.TripsContainer_Tabs__1rfHb:hover {
  font-weight: bold;
  color: #346ba4;
}

/* Advertisement Styling  */
.TripsContainer_AdContainer__1maZj {
  width: 330px;
  height: 500px;
  min-width: 330px;
  min-height: 500px;
  margin-left: 5rem;
  border-radius: 15px;
  background-color: #fff;
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: 0 1px 1px rgba(0,0,0,0.15), 
              0 2px 2px rgba(0,0,0,0.15), 
              0 4px 4px rgba(0,0,0,0.15), 
              0 8px 8px rgba(0,0,0,0.15);
  position: relative;
  display: flex;
  flex-direction: column;
  background: url(../../static/media/mask-man.d253933d.svg) 160% 70% no-repeat,
              url(../../static/media/ellipse_11.11b5480c.svg) center right no-repeat;
  background-size: 250px 200px, 150px 150px;

}

.TripsContainer_AdContainer__1maZj .TripsContainer_CloseButton__34z-W {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}

.TripsContainer_AdContainer__1maZj .TripsContainer_CloseButton__34z-W:after{
  content: "\00d7"; /* This will render the 'X' */
  font-size: 2rem;
}

.TripsContainer_AdContainer__1maZj h3 {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  margin: 1rem 0;
}

.TripsContainer_AdContainer__1maZj p {
  color: var(--dark-grey);
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.TripsContainer_AdContainer__1maZj img {
  width: 140px;
  margin: 1rem 0;
}

@media(max-width: 1200px){
  
  .TripsContainer_MyTrip__15brh {
    width: 40%;
  }
}

/* Mobile @media */
@media only screen and (max-width: 767px) {
  .TripsContainer_TripsContainer__1W5G2 .TripsContainer_MyTrip__15brh {
    min-width: 100%;
  }

  .TripsContainer_AdContainer__1maZj {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .TripsContainer_TripsContainer__1W5G2 .TripsContainer_MyTrip__15brh {
    min-width: 100%;
  }

  .TripsContainer_AdContainer__1maZj {
    display: none;
  }
}
.ExploreHeader_ExploreHeader__2nmou {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}

.ExploreHeader_ExploreHeader__2nmou h1 {
  font-size: 22px;
  font-weight: 500;
}

.ExploreHeader_ExploreHeader__2nmou a {
  background-color: var(--primary-blue);
  color: var(--white) !important;
  padding: 0.9rem 1.5rem;
  border-radius: 10px;
  box-sizing: border-box;
  font-weight: 300;
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
  font-size: 16px;
  text-decoration: none;
}

.ExploreHeader_ExploreHeader__2nmou a:active {
  transform: scale(0.9, 0.9);
}

.ExploreHeader_ExploreHeader__2nmou a:hover {
  background-color: var(--secondary-blue);
}

@media (max-width: 767px) {
  .ExploreHeader_ExploreHeader__2nmou {
    margin-bottom: 1.5rem;
    align-items: flex-start;
  }

  .ExploreHeader_ExploreHeader__2nmou a {
    display: block;
    background-color: #fafafa;
    color: var(--primary-blue) !important;
    padding: 0 1.5rem;
    margin-top: -12px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .ExploreHeader_ExploreHeader__2nmou a p:first-child {
    font-size: 30px;
  }
}
.BrandContainer_ChildContainer__1lINb {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BrandContainer_StoreDetails__2ruGW {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
}

.BrandContainer_StoreDetails__2ruGW img{
  width: 90px;
  max-height: 45px;
  padding-right: 1rem;
}

.BrandContainer_StoreDetails__2ruGW div {
  line-height: 1.3rem;
}

.BrandContainer_StoreDetails__2ruGW p {
  color: var(--dark-grey);
  font-size: 0.9rem;
  opacity: 0.9;
}

.BrandContainer_ChildContainer__1lINb i {
  margin-left: 1rem;
}

@media only screen and (max-width: 767px) {
  .BrandContainer_StoreDetails__2ruGW img {
    width: 120px;
  }

  .BrandContainer_StoreDetails__2ruGW div {
    line-height: 1.5rem;
  }

  .BrandContainer_StoreDetails__2ruGW h4 {
    font-size: 18px;
    line-height: normal;
  }

  .BrandContainer_StoreDetails__2ruGW p {
    font-size: 0.9rem;
    line-height: normal;
  }
}
.SearchByBrand_SearchBrand__19Ezx {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 2rem 0;
}

.SearchByBrand_BrandContainer__2osmZ {
  flex-basis: 48%;
  min-width: 300px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 1rem 0;
  padding: 1rem;
  background: var(--white);
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}


@media only screen and (max-width: 1350px) {
  .SearchByBrand_SearchBrand__19Ezx {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .SearchByBrand_SearchBrand__19Ezx {
    width: 100%;
  }

  .SearchByBrand_BrandContainer__2osmZ {
    width: 100%;
    flex-basis: 100%;
    padding: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .SearchByBrand_BrandContainer__2osmZ {
    min-width: auto;
    flex-basis: 47%;
  }

  .SearchByBrand_SearchBrand__19Ezx {
    width: 100%;
  }
}
.SalesStore_SalesStore__3pDF6 {
  width: 60%;
}

.SalesStore_SalesStore__3pDF6 .SalesStore_Header__2h6gH {
  margin: 2rem 0;
}

.SalesStore_SalesStore__3pDF6 .SalesStore_Header__2h6gH h3 {
  font-weight: 500;
  font-size: 1.4rem;
}

.SalesStore_SalesStore__3pDF6 .SalesStore_Header__2h6gH p {
  margin: 0.5rem 0;
}

.SalesStore_SalesStore__3pDF6 .SalesStore_Header__2h6gH a {
  background-color: var(--primary-blue);
}

.SalesStore_SalesStore__3pDF6 .SalesStore_Header__2h6gH a:hover {
  background-color: var(--secondary-blue);
}

.SalesStore_SalesStore__3pDF6 .SalesStore_Header__2h6gH button,
.SalesStore_SalesStore__3pDF6 .SalesStore_Header__2h6gH a {
  padding: 0.75rem 1rem;
  margin: 0 0.3rem 0 0;
  font-size: 0.75rem;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: var(--white);
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
  cursor: pointer;
}

.SalesStore_SalesStore__3pDF6 .SalesStore_Header__2h6gH .SalesStore_AddTrip__3Z7Hb {
  background-color: var(--primary-blue);
}

.SalesStore_SalesStore__3pDF6 .SalesStore_Header__2h6gH .SalesStore_AddTrip__3Z7Hb:hover {
  background-color: var(--secondary-blue);
}

.SalesStore_SalesStore__3pDF6 .SalesStore_Header__2h6gH .SalesStore_UpdateTrip__3SHEP {
  background-color: #17b617;
}

.SalesStore_SalesStore__3pDF6 .SalesStore_Header__2h6gH .SalesStore_UpdateTrip__3SHEP:hover {
  background-color: #43ac6a;
}

.SalesStore_SalesStore__3pDF6 .SalesStore_SalesContainer__2YquP {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  justify-content: space-between;
  list-style: none;
}

@media (max-width: 1200px) {
  .SalesStore_SalesStore__3pDF6 {
    width: 80%;
  }
}
.StoreContainer_StoreContainer__3z9d0 {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  align-items: center;
}

.StoreContainer_StoreContainer__3z9d0 h4 {
  margin-bottom: 0.25rem;
}

@media only screen and (max-width: 767px) {
  .StoreContainer_StoreContainer__3z9d0 h4 {
    font-size: 16px;
  }

  .StoreContainer_StoreContainer__3z9d0 p {
    font-size: 1rem;
  }
}
.PostalCodeContainer_PostalCodeContainer__2UZJc {
  display: flex;
  flex-basis: 100%;
  margin-top: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.PostalCodeContainer_PostalCodeContainer__2UZJc input {
  padding: 1rem;
  width: 20%;
  background-color: #F1F1F1;
  border: 1px solid var(--dark-grey);
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  box-sizing: border-box;
  margin-right: 4rem;
}

.PostalCodeContainer_PostalCodeContainer__2UZJc input:focus {
  background-color: var(--white);
  outline: none;
  border: 2px solid var(--primary-blue);
}

.PostalCodeContainer_PostalCodeContainer__2UZJc p {
  text-transform: uppercase;
  color: var(--black) !important;
  margin-right: 4rem;
  font-size: 1.2rem !important;
  opacity: 1 !important;
}

.PostalCodeContainer_PostalCodeContainer__2UZJc a {
  text-decoration: none;
  color: var(--black);
}

.PostalCodeContainer_PostalCodeContainer__2UZJc .PostalCodeContainer_BtLoc__2wA96 {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--dark-grey);
  background-color: #F1F1F1;
  padding: 1rem;
  color: var(--dark-grey);
  font-size: 1.1rem;
  text-transform: capitalize;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  box-sizing: border-box;
}

.PostalCodeContainer_PostalCodeContainer__2UZJc .PostalCodeContainer_BtLoc__2wA96:active {
  background-color: var(--white);
  border: 1px solid var(--primary-blue);
}

.PostalCodeContainer_PostalCodeContainer__2UZJc .PostalCodeContainer_BtLoc__2wA96 i {
  padding-right: 0.5rem;
  color: var(--black);
  font-size: 1.3rem;
}

@media only screen and (max-width: 767px) {
  .PostalCodeContainer_PostalCodeContainer__2UZJc {
    flex-direction: column;
    align-items: flex-start;
  }

  .PostalCodeContainer_PostalCodeContainer__2UZJc input,
  .PostalCodeContainer_PostalCodeContainer__2UZJc .PostalCodeContainer_BtLoc__2wA96 {
    width: 100%;
    margin: 1rem auto;
  }

  .PostalCodeContainer_PostalCodeContainer__2UZJc p {
    margin: 0;
    width: 70%;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .PostalCodeContainer_PostalCodeContainer__2UZJc .PostalCodeContainer_BtLoc__2wA96 {
    font-size: 0.9rem;
  }
}
.Search_SearchStore__3hBlZ p {
  color: var(--dark-grey);
  font-size: 0.9rem;
  opacity: 0.9;
}

.Search_StoreContainer__qAzpb {
  width: 60%;
  min-width: 600px;
  background-color: var(--white);
  height: auto;
  padding: 1.5rem;
  margin: 2rem 0;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}

@media only screen and (max-width: 767px) {
  .Search_StoreContainer__qAzpb {
    min-width: auto;
    width: auto;
    padding: 1rem 1.5rem;
  }

  .Search_SearchStore__3hBlZ form {
    margin-bottom: 5rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Search_StoreContainer__qAzpb {
    width: 100%;
    min-width: auto;
  }
}
.SearchByMyLocation_ProximityContainer__2lIYg > h2 {
  font-weight: 500;
  margin-top: 2rem;
}

.SearchByMyLocation_ProximityContainer__2lIYg > p {
  margin-top: 2rem;
}

.SearchByMyLocation_SearchByLocation__3RgIs {
  width: 60%;
  margin-top: 2rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.SearchByMyLocation_LocationWrapper__CEK5_ {
  flex-basis: 48%;
  min-width: 300px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 1rem 0;
  padding: 1.5rem 1rem;
  background: var(--white);
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}

@media (max-width: 1200px) {
  .SearchByMyLocation_SearchByLocation__3RgIs {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .SearchByMyLocation_SearchByLocation__3RgIs {
    width: 100%;
  }

  .SearchByMyLocation_LocationWrapper__CEK5_ {
    width: 100%;
    flex-basis: 100%;
  }
}
.StoreContainer_ChildContainer__1fTQS {
  display: flex;
  justify-content: space-between;
}

.StoreContainer_ChildContainer__1fTQS div p {
  color: var(--dark-grey);
  padding-top: 0.3rem;
  font-size: 0.9rem;
}

.StoreContainer_ChildContainer__1fTQS i {
  margin-left: 1rem;
}

@media only screen and (max-width: 767px){
  .StoreContainer_ChildContainer__1fTQS div {
    line-height: 1rem;
  }

  .StoreContainer_ChildContainer__1fTQS h4 {
    line-height: 1.3rem;
    font-size: 18px;
  }
}
.FilterByStoreLocation_FilterStore__1rWoM {
  width: 60%;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 2rem 0;
}

.FilterByStoreLocation_StoreContainer__C3-ky {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  flex-basis: 50%;
}

.FilterByStoreLocation_StoreContainer__C3-ky h3 {
  font-weight: 500;
  font-size: 1.4rem;
}

.FilterByStoreLocation_StoreWrapper__3iHeA {
  width: 95%;
  min-width: 300px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 1rem 0;
  padding: 1.5rem 1rem;
  background: var(--white);
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
  min-height: 100px;
}

@media (max-width: 1200px) {
  .FilterByStoreLocation_FilterStore__1rWoM {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .FilterByStoreLocation_FilterStore__1rWoM {
    width: 100%;
  }

  .FilterByStoreLocation_StoreContainer__C3-ky {
    flex-basis: 100%;
  }

  .FilterByStoreLocation_StoreWrapper__3iHeA {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .FilterByStoreLocation_FilterStore__1rWoM {
    width: 100%;
  }
}
.LocationContainer_LocationContainer__116yf {
	display: flex;
	justify-content: space-between;
	line-height: normal;
}

.LocationContainer_LocationDetails__3OBuL {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
  
.LocationContainer_LocationDetails__3OBuL p {
	color: #808080;
	font-size: 0.9rem;
	opacity: 0.9;
}

@media only screen and (max-width: 767px) {
	.LocationContainer_LocationContainer__116yf {
		line-height: 1.4rem;
	}

	.LocationContainer_LocationDetails__3OBuL h4 {
		font-size: 18px;
	}

	.LocationContainer_LocationDetails__3OBuL p {
		font-size: 1rem;
	}
}
.SearchByCity_SearchByCity__1cztw {
  width: 60%;
  margin: 2rem 0;
}

.SearchByCity_ProvinceContainer__3E0MZ {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
}

.SearchByCity_ProvinceContainer__3E0MZ h3 {
  font-weight: 500;
  font-size: 1.4rem;
}

.SearchByCity_ProvinceContainer__3E0MZ .SearchByCity_LocationContainer__28BkL {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin-top: 2rem;
}

.SearchByCity_LocationWrapper__3EyyE {
  flex-basis: 48%;
  min-width: 300px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 1rem 0;
  padding: 1.5rem 1rem;
  background: var(--white);
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}

@media only screen and (max-width: 1350px) {
  .SearchByCity_SearchByCity__1cztw {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .SearchByCity_SearchByCity__1cztw {
    width: 100%;
  }

  .SearchByCity_LocationWrapper__3EyyE {
    flex-basis: 100%;
  }
}
.StoreByCity_StoreByCity__332fb {
  width: 60%;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 2rem 0;
}

.StoreByCity_StoreContainer__3Y3oR {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  flex-basis: 50%;
}

.StoreByCity_StoreContainer__3Y3oR h3 {
  font-weight: 500;
  font-size: 1.4rem;
}

.StoreByCity_StoreWrapper__1YaHX {
  width: 95%;
  min-width: 300px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 1rem 0;
  padding: 1rem;
  background: var(--white);
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}

@media(max-width: 1350px){
  .StoreByCity_StoreByCity__332fb {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .StoreByCity_StoreByCity__332fb {
    width: 100%;
  }

  .StoreByCity_StoreContainer__3Y3oR {
    flex-basis: 100%;
  }

  .StoreByCity_StoreWrapper__1YaHX {
    width: 100%;
    padding: 1rem;
  }
}
.Landing_Landing__2fDas {
  width: 100%;
  position: relative;
  top: 4rem;
}

/* Banner */
.Landing_Banner__318l9 {
  z-index: 200;
  min-height: 56px;
  width: 100%;
  background: var(--positive-green);
  position: fixed;
}

.Landing_Banner__318l9 > div {
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
}

.Landing_Banner__318l9 > div span p,
.Landing_Banner__318l9 > div span a {
  font-size: 13px;
  display: inline;
  color: var(--white);
}

.Landing_Banner__318l9 > div .Landing_CloseBtn__2rZfF:after {
  content: "\00d7"; /* This will render the 'X' */
  font-size: 26px;
  color: var(--white);
  cursor: pointer;
}

/* First Section Styles (Find Stores) */
.Landing_FindStores__1qfgE {
  height: calc(100vh - 10rem);
  position: relative;
  display: flex;
  align-items: center
}

.Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK {
  width: 625px;
  min-width: 625px;
  background-color: var(--white);
  padding: 3rem 3rem;
  box-sizing: border-box;
  margin: 5rem 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  z-index: 10;
  margin-top: -5rem;
}

.Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK h2 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK h4 {
  color: var(--small-label);
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  font-weight: normal;
  margin-bottom: 2rem;
}

.Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK form {
  border: none;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  height: 60px;
}

.Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK form input[type=text] {
  outline: none;
  border: 2px solid var(--primary-blue);
  width: 70%;
  background-color: #F1FAFF;
  color: var(--primary-blue);
  padding: 1.5rem;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  box-sizing: border-box;
  font-size: 0.8rem;
  height: 100%;
  text-align: center;
}

.Landing_LocationContainer__3exgK form input[type=text]::-webkit-input-placeholder {
  color: var(--primary-blue);
}

.Landing_LocationContainer__3exgK form input[type=text]:-ms-input-placeholder {
  color: var(--primary-blue);
}

.Landing_LocationContainer__3exgK form input[type=text]::-ms-input-placeholder {
  color: var(--primary-blue);
}

.Landing_LocationContainer__3exgK form input[type=text]::placeholder {
  color: var(--primary-blue);
}

.Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK form button {
  width: 30%;
  outline: none;
  background-color: var(--primary-blue);
  border: none;
  box-sizing: border-box;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  height: 100%;
  font-size: 0.8rem;
  color: var(--white);
  font-weight: normal;
  cursor: pointer;
}

.Landing_LocationContainer__3exgK form button i {
  margin-right: 0.7rem;
  font-size: 1rem;
}

.Landing_LocationContainer__3exgK .Landing_FindMyLoc__2iMWZ {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Landing_LocationContainer__3exgK .Landing_FindMyLoc__2iMWZ p {
  color: var(--black);
  margin: 0 2rem 0 0;
}

.Landing_LocationContainer__3exgK .Landing_FindMyLoc__2iMWZ button {
  outline: none;
  background: none;
  border: none;
  color: var(--primary-blue);
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.Landing_LocationContainer__3exgK .Landing_FindMyLoc__2iMWZ button i {
  margin-right: 5px;
  font-size: 1.2rem;
}

.Landing_FindStores__1qfgE img {
  width: 500px;
  position: absolute;
  bottom: 15%;
  left: 500px;
}

/* Second Section Styles (Application Overview) */
.Landing_Overview__1vfE4 {
  width: 100%;
  background-color: var(--white);
}

.Landing_OverviewContainer__1G-Km {
  display: flex;
  align-items: center;
  flex-flow: wrap;
}

.Landing_OverviewContainer__1G-Km .Landing_OverviewDescription__3i23q {
  flex-basis: 50%;
  min-width: 400px;
  padding: 2rem 0;
  box-sizing: border-box;
  margin: 5rem 0;
}

.Landing_OverviewContainer__1G-Km div {
  flex-basis: 50%;
  min-width: 400px;
}

.Landing_OverviewContainer__1G-Km .Landing_OverviewDescription__3i23q h2 {
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: 1.5rem;
  font-size: 1.7rem;
}

.Landing_OverviewContainer__1G-Km .Landing_OverviewDescription__3i23q p {
  color: var(--small-label);
  line-height: 1.7rem;
  font-size: 1.1rem;
}

.Landing_OverviewContainer__1G-Km img {
  width: 100%;
  margin: 5rem 0;
}

/* Section 3 - Stores Advertisement */
.Landing_Stores__1nHwz {
  height: calc(100vh - 7.5rem);
}

.Landing_Stores__1nHwz h2 {
  text-transform: uppercase;
  font-style: italic;
  width: 50%;
  margin: 0 auto;
  min-width: 400px;
  font-size: 1.8rem;
  padding-top: 5rem;
  text-align: center;
  line-height: 2.7rem;
}

.Landing_Stores__1nHwz .Landing_StoresContainer__3-nET {
  display: flex;
  flex-flow: row wrap;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

.Landing_Stores__1nHwz .Landing_StoreImage__2pb55 {
  flex-basis: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
}

.Landing_Stores__1nHwz .Landing_StoreImage__2pb55 img {
  width: 50%;
}

/* Apply to be a Mapper  */
.Landing_MapperApplication__1kbwu {
  width: 100%;
  background: var(--white);
  height: 487px;
  box-sizing: content-box;
  margin: 0;
}

.Landing_MapperApplication__1kbwu img {
  display: none;
}

.Landing_MapperApplication__1kbwu .Landing_Wrapper__23saj {
  background: linear-gradient(90deg, #FFFFFF 11.4%, rgba(255, 255, 255, 0) 40.66%), url(../../static/media/bg-mapper.947d120c.png);
  background-repeat: no-repeat;
  height: 100%;
  margin-left: 12.5%;
  background-size: 70% 100%;
  background-position: right;
}

.Landing_MapperApplication__1kbwu .Landing_Wrapper__23saj > div {
  width: 501px;
  padding-top: 100px;
}

.Landing_MapperApplication__1kbwu .Landing_Wrapper__23saj > div h2 {
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: 1.5rem;
  font-size: 1.7rem;
  color: var(--black);
}

.Landing_MapperApplication__1kbwu .Landing_Wrapper__23saj > div p {
  color: var(--small-label);
  line-height: 1.7rem;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.Landing_MapperApplication__1kbwu .Landing_Wrapper__23saj > div a {
  text-decoration: none;
  color: var(--primary-blue);
  size: 16px;
  font-weight: 400;
  border: 2px solid var(--primary-blue);
  padding: 17px 23px;
  background-color: var(--white);
  display: inline-block;
  text-transform: uppercase;
  border-radius: 7px;
}

.Landing_MapperApplication__1kbwu .Landing_Wrapper__23saj > div a:hover {
  background-color: var(--primary-blue);
  color: var(--white);
  transition: background-color 300ms linear;
  transition: color 300ms linear;
  -webkit-transition: color 300ms linear;
  -ms-transition: color 300ms linear;
}

/* Section 4 - Mobile app ad - On the go */
.Landing_MobileApp__3EF7Q {
  height: calc(100vh - 4rem);
  background-color: var(--primary-blue);
  width: 100%;
}

.Landing_MobileApp__3EF7Q .Landing_MobileWrapper__3FusK {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.Landing_MobileApp__3EF7Q .Landing_InnerCircleContainer__2uzeO {
  width: 50%;
  min-width: 400px; 
}

.Landing_MobileApp__3EF7Q .Landing_InnerCircle__3ThO3 {
  z-index: 2;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #F1C94C;
  position: relative;
}

.Landing_MobileApp__3EF7Q .Landing_InnerCircle__3ThO3 img {
  width: 220px;
  position: absolute;
  left: 50%;
  top: -10%;
  transform: translateX(-50%);
  border-radius: 15px;
}

.Landing_MobileApp__3EF7Q .Landing_Description__1LUPT {
  width: 50%;
  min-width: 400px;
}

.Landing_MobileApp__3EF7Q .Landing_Description__1LUPT h1 {
  color: white;
  font-style: italic;
  text-transform: uppercase;
}

.Landing_MobileApp__3EF7Q .Landing_Description__1LUPT p {
  color: #E0E0E0;
  font-size: 1.1rem;
  line-height: 2rem;
  margin: 2rem 0;
}

.Landing_MobileApp__3EF7Q .Landing_Description__1LUPT img {
  width: 150px;
  margin-right: 1.5rem;
}


/* Footer Section */
.Landing_Footer__UVsbH {
  width: 100%;
  background-color: var(--deep-blue);
  padding: 5rem 5rem;
  box-sizing: border-box;
}

.Landing_Footer__UVsbH div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Landing_Footer__UVsbH p {
  color: var(--light-grey);
}

.Landing_Footer__UVsbH a,
.Landing_Footer__UVsbH p:last-child {
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
}

@media (min-width: 1500px) {
  .Landing_MapperApplication__1kbwu .Landing_Wrapper__23saj {
    margin-left: 20%;
  }
}

/* Media Queries */
/* Mobile Phone */
@media (max-width: 767px){
  .Landing_Banner__318l9 {
    bottom: 0;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
  }

  .Landing_Banner__318l9 > div {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding: 0.5rem 0;
  }

  /* First Section Styles (Find Stores) */
  .Landing_FindStores__1qfgE {
    height: auto;
    top: 2rem;
    padding: 2rem 0;
  }

  .Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK {
    width: 100%;
    padding: 1.5rem;
    min-width: auto;
    margin: 6rem 0;
  }

  .Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK h2 {
    font-size: 1.4rem;
  }

  .Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK h4 {
    font-size: 1rem;
  }

  .Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK form {
    width: 100%;
  }

  .Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK form input[type=text] {
    padding: 0.75rem;
    font-size: 0.75rem;
    height: 40px;
  }

  .Landing_FindStores__1qfgE .Landing_LocationContainer__3exgK form button {
    height: 40px;
    padding: 0.75rem;
  }

  .Landing_LocationContainer__3exgK form button span {
    display: none;
  }

  .Landing_FindStores__1qfgE img {
    width: 110%;
    top: 45%;
    left: -10px;
  }

  /* Second Section Styles (Application Overview) */
  .Landing_OverviewContainer__1G-Km .Landing_MobileOrder__1NAq0 {
    order: 2;
  }
  
  .Landing_OverviewContainer__1G-Km .Landing_OverviewDescription__3i23q {
    width: 100%;
    min-width: auto;
    flex-basis: 100%;
    margin: 2rem 0;
    text-align: center;
  }

  .Landing_OverviewContainer__1G-Km div {
    flex-basis: 100%;
    min-width: auto;
  }

  .Landing_OverviewContainer__1G-Km div img {
    margin: 1rem 0;
  }

  /* Section 3 - Stores Advertisement */
  .Landing_Stores__1nHwz {
    height: auto;
  }

  .Landing_Stores__1nHwz h2 {
    width: 100%;
    min-width: auto;
    font-size: 1.5rem;
  }

  .Landing_Stores__1nHwz .Landing_StoreImage__2pb55 {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Landing_Stores__1nHwz .Landing_StoreImage__2pb55 img {
    width: 80%;
  }

  /* Apply to be a mapper */
  .Landing_MapperApplication__1kbwu {
    height: auto;
  }

  .Landing_MapperApplication__1kbwu img {
    display: block;
    width: 100%;
    height: 256px;
  }

  .Landing_MapperApplication__1kbwu .Landing_Wrapper__23saj {
    background: var(--white);
    margin: 0 12.5%;
    text-align: center;
  }

  .Landing_MapperApplication__1kbwu .Landing_Wrapper__23saj > div {
    padding: 100px 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  /* Section 4 - Mobile app ad - On the go */
  .Landing_MobileApp__3EF7Q {
    height: auto;
  }

  .Landing_MobileApp__3EF7Q .Landing_InnerCircleContainer__2uzeO {
    width: 100%;
    min-width: auto;
    margin: 5rem 0;
  }

  .Landing_MobileApp__3EF7Q .Landing_InnerCircle__3ThO3 {
    z-index: 2;
    width: 100%;
    height: auto;
    padding-top: 100%;
    border-radius: 50%;
    background-color: #F1C94C;
  }

  .Landing_MobileApp__3EF7Q .Landing_InnerCircle__3ThO3 img {
    width: 160px;
  }

  .Landing_MobileApp__3EF7Q .Landing_Description__1LUPT {
    width: 100%;
    margin-bottom: 5rem;
    min-width: auto;
  }

  .Landing_MobileApp__3EF7Q .Landing_Description__1LUPT h1 {
    text-align: center;
  }

  .Landing_MobileApp__3EF7Q .Landing_Description__1LUPT p {
    text-align: center;
  }

  .Landing_MobileApp__3EF7Q .Landing_Description__1LUPT div {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
  }

  .Landing_MobileApp__3EF7Q .Landing_Description__1LUPT img {
    margin: 1rem 0;
  }

  /* Footer Section */
  .Landing_Footer__UVsbH {
    padding: 5rem 3rem;
  }

  .Landing_Footer__UVsbH div {
    flex-direction: column;
  }

  .Landing_Footer__UVsbH div p,
  .Landing_Footer__UVsbH div a {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Landing_Overview__1vfE4 .Landing_OverviewContainer__1G-Km {
    justify-content: center;
  }

  .Landing_Overview__1vfE4 .Landing_OverviewContainer__1G-Km .Landing_OverviewDescription__3i23q {
    margin: 3rem 0;
  }

  .Landing_Stores__1nHwz {
    height: auto;
  }

  .Landing_MobileApp__3EF7Q {
    height: auto;
    padding: 5rem 0;
  }

  .Landing_MobileApp__3EF7Q .Landing_MobileWrapper__3FusK {
    justify-content: center;
  }

  .Landing_MobileApp__3EF7Q .Landing_MobileWrapper__3FusK .Landing_InnerCircleContainer__2uzeO {
    margin-bottom: 5rem;
  }

  .Landing_MobileApp__3EF7Q .Landing_Description__1LUPT {
    margin-top: 5rem;
    text-align: center;
  }

  .Landing_Footer__UVsbH {
    padding: 5rem 3rem;
  }

  .Landing_Footer__UVsbH div {
    flex-direction: column;
  }

  .Landing_Footer__UVsbH div p,
  .Landing_Footer__UVsbH div a {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
  }
}
.LandingNavbar_LandingNavbar__33huz {
  width: 100%;
  background-color: var(--white);
  position: fixed;
  z-index: 100;
  height: 4rem;
  box-shadow: 0 1px 1px rgba(0,0,0,0.2);
}

.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 12.5%;
}

.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM img {
  width: 120px;
  padding: 0.75rem 0;
}

.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM img:active,
.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr .LandingNavbar_MenuOptions__3dh0H a:active {
  transform: scale(0.9, 0.9);
}

.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr .LandingNavbar_MenuOptions__3dh0H a {
  margin: 0.5rem 1rem;
  text-transform: uppercase;
  color: var(--black);
  text-decoration: none;
  cursor: pointer;
  font-size: 14px;
}

.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr .LandingNavbar_LoginBtn__li8sJ {
  text-transform: uppercase;
  border: 2px var(--primary-blue) solid;
  color: var(--primary-blue);
  cursor: pointer;
  padding: 0.5rem 1.5rem;
  text-decoration: none;
  border-radius: 7px;
}
.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr .LandingNavbar_LoginBtn__li8sJ:hover {
  background-color: #026AA4;
  color: #fff;
  transition: background-color 300ms linear;
  transition: color 300ms linear;
  -webkit-transition: color 300ms linear;
  -ms-transition: color 300ms linear;
}

.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr div span {
  color: var(--primary-blue);
}

.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr .LandingNavbar_LogOutBtn__1a2ct {
  color: #1B77F2;
  margin-left: 0.25rem;
  font-size: 0.9rem;
  text-decoration: underline;
  cursor: pointer;
}

.LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr .LandingNavbar_LogOutBtn__1a2ct:hover {
  color: var(--primary-blue);
}

@media (min-width: 1500px) {
  .LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n {
    margin: 0 20%;
  }
}


@media (max-width: 767px){
  .LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM {
    width: 90%;
    margin: 0;
    justify-content: space-between;
    flex-direction: column;
    margin: 0 auto; 
  }

  .LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n {
    margin: 0;
    background-color: var(--white);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
  }

  .LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr{
    border-top: 2px solid rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    flex-direction: column;
  }

  .LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr div:last-child {
    justify-content: center;
    margin-bottom: 1rem;
  }

  .LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr .LandingNavbar_LoginBtn__li8sJ {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }

  .LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr .LandingNavbar_MenuOptions__3dh0H {
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  .LandingNavbar_LandingNavbar__33huz .LandingNavbar_LdContainer__1A68n .LandingNavbar_NavbarOptions__vKBTM .LandingNavbar_Options__WMXIr .LandingNavbar_MenuOptions__3dh0H a {
    margin: 0.5rem 0;
  }
}
.FormGroup_FormGroup__3sdMM {
  margin-bottom: 15px;
  min-width: 150px;
}

.FormGroup_FormGroup__3sdMM label span {
  color: #f00;
}

.FormGroup_FormGroup__3sdMM label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

/* Chrome, Safari, Edge, Opera */
.FormGroup_FormGroup__3sdMM input::-webkit-outer-spin-button,
.FormGroup_FormGroup__3sdMM input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.FormGroup_FormGroup__3sdMM input[type=number] {
  -moz-appearance: textfield;
}

.FormGroup_FormGroup__3sdMM input:disabled,
.FormGroup_FormGroup__3sdMM textarea:disabled {
  background: #ddd;
  font-weight: bold;
}

.FormGroup_FormControl__2bOC0 {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  box-sizing: border-box;
}

.FormGroup_FormControl__2bOC0:focus {
  outline: none;
  border: 2px solid var(--primary-blue);
}

.FormGroup_FormGroup__3sdMM textarea.FormGroup_FormControl__2bOC0 {
  height: auto;
}
.RequestStore_ReqStore__oSPM7 {
  width: 50%;
  max-width: 600px;
}

.RequestStore_ModalHeader__2iSZY {
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.RequestStore_ModalHeader__2iSZY h4 {
  font-size: 18px;
  font-weight: 500;
}

.RequestStore_ModalBody__3g-vv {
  padding: 15px 0;
}

.RequestStore_ModalFooter__3XGgI {
  padding: 15px 0;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
}

.RequestStore_ModalFooter__3XGgI button{
  margin-left: 5px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 6px 12px;
  border-radius: 4px;
}

.RequestStore_ModalFooter__3XGgI button:first-child{
  text-shadow: 0 1px 0 #fff;
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}

.RequestStore_ModalFooter__3XGgI button:first-child:hover{
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.RequestStore_ModalFooter__3XGgI button:last-child {
  background-color: var(--primary-blue);
  color: #fff;
}

.RequestStore_ModalFooter__3XGgI button:last-child:hover {
  color: #fff;
  background-color: var(--secondary-blue);
  border-color: var(--secondary-blue);
}

@media (max-width: 1350px) {
  .RequestStore_ReqStore__oSPM7 {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .RequestStore_ReqStore__oSPM7 {
    width: 100%;
    margin: 0 auto;
  }
}
.ReportIssue_ReqStore__1A4dw {
  width: 50%;
  max-width: 600px;
}

.ReportIssue_ModalHeader__oCCNy {
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.ReportIssue_ModalHeader__oCCNy h4 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.ReportIssue_ModalBody__3izSb {
  padding: 15px 0;
}

.ReportIssue_ModalFooter__3ACrR {
  padding: 15px 0;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
}

.ReportIssue_ModalFooter__3ACrR button{
  margin-left: 5px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 6px 12px;
  border-radius: 4px;
}

.ReportIssue_ModalFooter__3ACrR button:first-child{
  text-shadow: 0 1px 0 #fff;
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}

.ReportIssue_ModalFooter__3ACrR button:first-child:hover{
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.ReportIssue_ModalFooter__3ACrR button:last-child {
  background-color: var(--primary-blue);
  color: #fff;
}

.ReportIssue_ModalFooter__3ACrR button:last-child:hover {
  color: #fff;
  background-color: var(--secondary-blue);
  border-color: var(--secondary-blue);
}

@media (max-width: 1350px) {
  .ReportIssue_ReqStore__1A4dw {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .ReportIssue_ReqStore__1A4dw {
    width: 100%;
    margin: 10px auto;
  }

  .ReportIssue_ReqStore__1A4dw h4 {
    text-align: center;
  }
}
.Login_LoginContainer__2k6WW {
  width: 50%;
  max-width: 600px;
}

.Login_DataSpinner__aJUjU {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(185, 184, 184, 0.5);
}

.Login_TabsContainer__3o61B {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Login_Tabs__1pAUy {
  flex-basis: 50%;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e8e8e8;
  text-align: center;
  outline: none;
}

.Login_FormContainer__3ZEUk .Login_FormHeader__1PkDE h4 {
  font-size: 18px;
  font-weight: 500;
}

.Login_FormContainer__3ZEUk .Login_FormHeader__1PkDE {
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.Login_FormContainer__3ZEUk .Login_FormBody__K67wt {
  padding: 15px 0;
}

.Login_FormContainer__3ZEUk .Login_FormBody__K67wt form .Login_NameContainer__1AP8Y {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Login_FormContainer__3ZEUk .Login_FormBody__K67wt form .Login_NameContainer__1AP8Y div {
  flex-basis: 49%;
  min-width: 150px;
}

.Login_FormContainer__3ZEUk .Login_FormBody__K67wt form div {
  position: relative;
}

.Login_FormContainer__3ZEUk .Login_FormBody__K67wt form div i {
  opacity: 0.7;
  font-size: 12px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}

.Login_FormFooter__MOSST {
  padding: 15px 0;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.Login_FormFooter__MOSST a {
  color: #1B77F2;
  text-decoration: none;
}

.Login_FormFooter__MOSST a:hover {
  text-decoration: underline;
}

.Login_FormFooter__MOSST button{
  margin-left: 5px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 6px 12px;
  border-radius: 4px;
}

.Login_FormFooter__MOSST button:first-child{
  text-shadow: 0 1px 0 #fff;
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}

.Login_FormFooter__MOSST button:first-child:hover{
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.Login_FormFooter__MOSST button:last-child {
  background-color: var(--primary-blue);
  color: var(--white);
}

.Login_FormFooter__MOSST button:last-child:hover {
  color: var(--white);
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);
}

@media (max-width: 1024px) {
  .Login_LoginContainer__2k6WW {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .Login_LoginContainer__2k6WW {
    width: 100%;
  }

  .Login_FormContainer__3ZEUk .Login_FormBody__K67wt {
    padding: 15px 0 0 0;
  }

  .Login_FormFooter__MOSST {
    flex-direction: column;
  }

  .Login_FormFooter__MOSST a {
    order: 2;
  }

  .Login_FormFooter__MOSST > div {
    order: 1;
    width: 100%;
  }

  .Login_FormFooter__MOSST button {
    display: block;
    width: 100%;
    margin-bottom: 7px;
    height: 40px;
    margin-left: 0;
  }

  .Login_FormFooter__MOSST > div {
    display: flex;
    flex-direction: column;
  }

  .Login_FormFooter__MOSST button:first-child {
    order: 2;
  }

  .Login_FormFooter__MOSST button:last-child {
    order: 1;
  }
}
.Acknowlegement_Acknowlegement__2cXX0 {
  height: 600px;
  padding: 10px;
}

.Acknowlegement_Acknowlegement__2cXX0 h2 {
  font-size: 22px;
  padding-bottom: 10px;
  box-sizing: border-box;
  color: var(--primary-blue);
  font-weight: 400;
}

.Acknowlegement_Acknowlegement__2cXX0 .Acknowlegement_PolicyBox__1nAp3 {
  height: 475px;
  overflow: scroll;
  padding: 10px;
  border: 1px var(--black) solid;
}

.Acknowlegement_Acknowlegement__2cXX0 .Acknowlegement_Checkbox__oyWuO {
  cursor: pointer;
  margin: 10px 0;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.Acknowlegement_Acknowlegement__2cXX0 .Acknowlegement_Checkbox__oyWuO input {
  cursor: pointer;
}

.Acknowlegement_Acknowlegement__2cXX0 .Acknowlegement_Checkbox__oyWuO label {
  cursor: pointer;
  display: inline;
  padding-left: 15px;
}

.Acknowlegement_Acknowlegement__2cXX0 .Acknowlegement_SubmitButtons__2AHzQ {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Acknowlegement_Acknowlegement__2cXX0 .Acknowlegement_SubmitButtons__2AHzQ > button {
  margin-left: 5px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 6px 12px;
  border-radius: 4px;
}

.Acknowlegement_Acknowlegement__2cXX0 .Acknowlegement_SubmitButtons__2AHzQ > button:first-child{
  text-shadow: 0 1px 0 #fff;
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}

.Acknowlegement_Acknowlegement__2cXX0 .Acknowlegement_SubmitButtons__2AHzQ > button:first-child:hover{
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.Acknowlegement_Acknowlegement__2cXX0 .Acknowlegement_SubmitButtons__2AHzQ > button:last-child:enabled {
  background-color: var(--primary-blue);
  color: #fff;
}

.Acknowlegement_Acknowlegement__2cXX0 .Acknowlegement_SubmitButtons__2AHzQ > button:last-child:hover:enabled {
  color: #fff;
  background-color: var(--secondary-blue);
  border-color: var(--secondary-blue);
}

@media (max-width: 767px) {
  .Acknowlegement_Acknowlegement__2cXX0 h2 {
    text-align: center;
  }
}
.TermsBox_TermsBox__yys8u {
  padding: 5px;
}

.TermsBox_TermsBox__yys8u > div h3,
.TermsBox_TermsBox__yys8u > div h4,
.TermsBox_TermsBox__yys8u > div p {
  margin-bottom: 10px;
  text-align: justify;
}

.TermsBox_TermsBox__yys8u > div h3 {
  font-size: 19px;
  font-weight: 600;
}

.TermsBox_TermsBox__yys8u > div h4 {
  font-size: 17px;
  font-weight: 600;
}

.TermsBox_TermsBox__yys8u > div p {
  font-size: 14px;
}

.TermsBox_TermsBox__yys8u > div ul,
.TermsBox_TermsBox__yys8u > div ol {
  margin: 0 0 10px 40px;
  font-size: 14px;
}
.Modal_Modal__15G-v{
  position: fixed;
  z-index: 500;
  background-color: var(--white);
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  min-height: auto;
  overflow-y: auto;
  width: 600px;
  top: 40px;
  left: calc(50% - 300px);
  transform: translateX(-50%);
}

.Modal_Backdrop__3NKhc {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

@media only screen and (max-width: 767px){
  .Modal_Modal__15G-v {
    width: 90%;
    left: 5%;
  }
}
.ForgetPassword_ForgetPassword__aCR1- {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.ForgetPassword_ContentContainer__3yLmW {
  border: 2px var(--primary-blue) solid;
  padding: 2rem;
  background-color: #fff;
  width: 500px;
}

.ForgetPassword_ContentContainer__3yLmW h2 {
  color: var(--primary-blue);
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
}

.ForgetPassword_ContentContainer__3yLmW form {
  margin: 1rem 0;
}

.ForgetPassword_ContentContainer__3yLmW form input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: var(--white);
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  box-sizing: border-box;
  margin-bottom: 1rem;
  outline: none;
}

.ForgetPassword_ContentContainer__3yLmW form input:focus {
  outline: none;
  border: 2px solid var(--primary-blue);
}

.ForgetPassword_ContentContainer__3yLmW form button,
.ForgetPassword_ContentContainer__3yLmW a {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 6px 12px;
  border-radius: 4px;
  color: var(--white);
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);
}

@media only screen and (max-width: 767px) {
  .ForgetPassword_ForgetPassword__aCR1- {
    justify-content: flex-start;
    margin-top: 3rem;
  }

  .ForgetPassword_ContentContainer__3yLmW {
    width: 90%;
    border: none;
  }

  .ForgetPassword_ContentContainer__3yLmW h2 {
    font-size: 1.8rem;
  }

  .ForgetPassword_ContentContainer__3yLmW p {
    font-size: 1.2rem;
  }


  .ForgetPassword_ContentContainer__3yLmW form button,
  .ForgetPassword_ContentContainer__3yLmW form input {
    font-size: 1rem;
  }

  .ForgetPassword_ContentContainer__3yLmW form button {
    width: 100%;
    border: 1px #eee solid;
    padding: 0.5rem 0;
  }
}
.ResetPassword_ResetPassword__2TswS {
  width: 50%;
  max-width: 600px;
}

.ResetPassword_ModalHeader__27WkZ {
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.ResetPassword_ModalHeader__27WkZ h4 {
  font-size: 18px;
  font-weight: 500;
}

.ResetPassword_ModalBody__1BjwY {
  padding: 15px 0;
}

.ResetPassword_ModalFooter__16fv0 {
  padding: 15px 0;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
}

.ResetPassword_ModalFooter__16fv0 button{
  margin-left: 5px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 6px 12px;
  border-radius: 4px;
}

.ResetPassword_ModalFooter__16fv0 button:first-child{
  text-shadow: 0 1px 0 #fff;
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}

.ResetPassword_ModalFooter__16fv0 button:first-child:hover{
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.ResetPassword_ModalFooter__16fv0 button:last-child {
  background-color: var(--primary-blue);
  color: #fff;
}

.ResetPassword_ModalFooter__16fv0 button:last-child:hover {
  color: #fff;
  background-color: var(--secondary-blue);
  border-color: var(--secondary-blue);
}

@media (max-width: 1350px) {
  .ResetPassword_ResetPassword__2TswS {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .ResetPassword_ResetPassword__2TswS {
    width: 100%;
    margin: 0 auto;
  }
}
.SortOption_SortOption__dwXTH {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.SortOption_SortOption__dwXTH > span {
  margin-right: 4px;
}

.SortOption_SortOption__dwXTH select {
  padding: 5px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 1px #333 solid;
  font-size: 14px;
  padding: 5px 20px 5px 10px;
  outline: none;
  border-radius: 3px;
}

.SortOption_SortOption__dwXTH div {
  position: relative;
}

.SortOption_SortOption__dwXTH div span {
  position: absolute;
  height: 100%;
  width: 20px;
  top: 0;
  right: 0;
  box-sizing: border-box;
  pointer-events: none;
}

.SortOption_SortOption__dwXTH div span i {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .SortOption_SortOption__dwXTH {
    justify-content: center;
  }
}
.ExploreTab_ExploreTab__2Zafo {
  width: 100%;
  margin: 20px auto;
  -webkit-columns: 3;
          columns: 3;
  /* column-gap: 40px; */
}

.ExploreTab_ExploreTab__2Zafo .ExploreTab_InfiniteScroll__QqFpx {
  overflow: visible!important;
}

.ExploreTab_ExploreTab__2Zafo .ExploreTab_Hide__2yNyE {
  display: none;
}

.ExploreTab_ExploreTab__2Zafo .ExploreTab_Show__5LxQJ {
  display: block;
}

.ExploreTab_Project__1uTmm{
  width: 100%;
  display: inline-block;
  margin: 0 0 20px;
  padding: 4px;
  overflow: hidden;
  page-break-inside: avoid;
  -webkit-column-break-inside: avoid;
          break-inside: avoid;
  background-color: var(--white);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  /* z-index: 2; */
  box-sizing: border-box;
}

.ExploreTab_Project__1uTmm img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.ExploreTab_Project__1uTmm:hover {
  /* border: 1px solid #346BA4; */
  opacity: 0.93;
}

.ExploreTab_Project__1uTmm > div .ExploreTab_SaveBox__3VgBO {
  position: absolute;
  /* z-index: 10; */
  background-color: var(--primary-blue);
  border-radius: 10px;
  text-align: center;
  padding: 10px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}

.ExploreTab_Project__1uTmm > div .ExploreTab_SaveBox__3VgBO:hover,
.ExploreTab_Project__1uTmm > div .ExploreTab_EditBox__Uk12s:hover {
  background-color: var(--secondary-blue);
  opacity: 1 !important;
}

.ExploreTab_Project__1uTmm > div .ExploreTab_SaveBox__3VgBO i {
  margin-right: 8px;
}

.ExploreTab_Project__1uTmm > div .ExploreTab_SaveBox__3VgBO p {
  display: inline;
  font-weight: 300;
}

.ExploreTab_Project__1uTmm > div .ExploreTab_EditBox__Uk12s {
  position: absolute;
  left: 10px;
  top: 0;
  font-size: 20px;
  background-color: var(--primary-blue);
  padding: 10px;
  color: var(--white);
  border-radius: 50%;
}

.ExploreTab_Project__1uTmm > div .ExploreTab_ProjectMeta__1QljE {
  padding: 10px 1rem;
}

.ExploreTab_Project__1uTmm > div .ExploreTab_ProjectMeta__1QljE > p {
  margin: 14px 0 0;
  font-size: 16px;
  font-weight: 400;
}

.ExploreTab_Project__1uTmm > div .ExploreTab_ProjectMeta__1QljE > div span {
  border-radius: 100px;
  border: 1px #031016 solid;
  color: #031016;
  padding: 3px 7px;
  margin-right: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
}

@media (max-width: 1500px) {
  .ExploreTab_ExploreTab__2Zafo {
    -webkit-columns: 2;
            columns: 2;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .ExploreTab_ExploreTab__2Zafo {
    -webkit-columns: 1;
            columns: 1;
    padding: 0 1rem;
  }

  .ExploreTab_Project__1uTmm p {
    font-size: 12px;
  }
}
.MyListLogin_MyList__vI2fz {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MyListLogin_MyList__vI2fz p {
  font-style: italic;
  margin: 3rem 0 4rem 0;
  text-align: center;
}

.MyListLogin_MyList__vI2fz a {
  text-decoration: none;
  background-color: var(--primary-blue);
  color: var(--white) !important;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-sizing: border-box;
  font-weight: 300;
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
}

.MyListLogin_MyList__vI2fz a:hover {
  background-color: var(--secondary-blue);
}

@media (max-width: 768px) {
  .MyListLogin_MyList__vI2fz .MyListLogin_LoginList__3Y0BE {
    position: absolute;
    top: 30%;
    font-size: 1.2em;
  }

  .MyListLogin_MyList__vI2fz a {
    position: absolute;
    bottom: 100px;
    width: 90%;
  }
}
.ListContainer_ListContainer__2p5Ot {
  display: flex;
  height: 84px;
  width: 100%;
  background-color: var(--white);
  border-radius: 10px;
  box-sizing: border-box;
  overflow: hidden;
  margin-bottom: 15px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}

.ListContainer_ListContainer__2p5Ot:hover {
  background-color: #fdf7e7;
}

.ListContainer_ListContainer__2p5Ot img {
  width: 125px;
  min-width: 125px;
  height: 100%;
}

.ListContainer_ListContainer__2p5Ot > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 84px;
  padding-left: 0.75rem;
}

.ListContainer_ListContainer__2p5Ot > div > i {
  margin-right: 1.74rem;
  font-size: 14px;
}

.ListContainer_ListContainer__2p5Ot .ListContainer_ListDetails__2DTuB {
  flex-basis: 100%;
}

.ListContainer_ListContainer__2p5Ot .ListContainer_ListDetails__2DTuB > div {
  margin: 10px 0;
  display: flex;
  width: 100%;
}

.ListContainer_ListContainer__2p5Ot .ListContainer_ListDetails__2DTuB > div span {
  border-radius: 100px;
  border: 1px #031016 solid;
  color: #031016;
  padding: 3px 7px;
  margin-right: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
}

.ListContainer_ListContainer__2p5Ot .ListContainer_ListDetails__2DTuB > div span i {
  margin-right: 2px;
}

.ListContainer_ListContainer__2p5Ot .ListContainer_ListDetails__2DTuB p {
  font-size: 16px;
}

.ListContainer_ListContainer__2p5Ot > div > .ListContainer_ButtonsBox__1KUL2 {
  display: flex;
}

.ListContainer_ListContainer__2p5Ot > div > div button {
  outline: none;
  padding: 0.5rem 1rem;
  margin-left: 0.75rem;
  border-radius: 15px;
  cursor: pointer;
  display: none;
  transition: all 0.2s;
  font-size: 10px;
}

.ListContainer_ListContainer__2p5Ot > div > div button:hover {
  color: var(--white);
  border: 1px #000 solid;
}

.ListContainer_ListContainer__2p5Ot .ListContainer_EditBtn__25VyO {
  background: #F1FAFF;
  color: var(--primary-blue);
  border: 2px solid var(--primary-blue);
}

.ListContainer_ListContainer__2p5Ot .ListContainer_EditBtn__25VyO:hover {
  background: var(--primary-blue);
}

.ListContainer_ListContainer__2p5Ot .ListContainer_RemoveBtn__2EZ55 {
  border: 1px #AB2C2C solid;
  background: #FAE8E8;
  color: #AB2C2C;
}

.ListContainer_ListContainer__2p5Ot .ListContainer_RemoveBtn__2EZ55:hover {
  background: #AB2C2C;
}

.ListContainer_ListContainer__2p5Ot:hover button {
  display: block;
}

.ListContainer_ListContainer__2p5Ot:hover .ListContainer_ListDetails__2DTuB {
  flex-basis: 65%;
}

@media( max-width: 767px ) {
  .ListContainer_ListContainer__2p5Ot img {
    width: 90px;
    min-width: 90px;
    height: auto;
  }

  .ListContainer_ListContainer__2p5Ot > div {
    height: 100%;
  }

  .ListContainer_ListContainer__2p5Ot .ListContainer_ListDetails__2DTuB p {
    font-size: 13px;
    padding-right: 0.75rem;
    margin-bottom: 0.75rem;
  }


  .ListContainer_ListContainer__2p5Ot .ListContainer_ListDetails__2DTuB > div span {
    margin-right: 6px;
  }


  .ListContainer_ListContainer__2p5Ot button,
  .ListContainer_ListContainer__2p5Ot:hover button,
  .ListContainer_ListContainer__2p5Ot > div > i {
    display: none;
  }

  .ListContainer_ListContainer__2p5Ot:hover .ListContainer_ListDetails__2DTuB {
    flex-basis: 100%;
  }
}
.MyList_MyList__36r90 {
  width: 100%;
}

.MyList_MyList__36r90 > a {
  width: 100%;
  height: 84px;
  text-decoration: none;
  color: var(--black);
}

@media (max-width: 767px) {
  .MyList_MyList__36r90 > a {
    height: auto;
  }
}
.Explore_ExploreContainer__9Izk3 {
  width: 60%;
  min-width: 900px;
}

.Explore_TabsContainer__1DMTa {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Explore_Tabs__q8-CU {
  flex-basis: 50%;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e8e8e8;
  text-align: center;
  outline: none;
}

@media(max-width: 1500px){
  .Explore_ExploreContainer__9Izk3 {
    width: 70%;
    min-width: 600px;
  }

  .Explore_TabsContainer__1DMTa {
    min-width: 600px;
  }
}

/* Mobile @media */
@media only screen and (max-width: 767px) {
  .Explore_ExploreContainer__9Izk3 {
    width: 100%;
    min-width: 200px;
  }

  .Explore_TabsContainer__1DMTa {
    min-width: auto;
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Explore_ExploreContainer__9Izk3 {
    min-width: 100%;
  }
}
.ListContainer_ListContainer__124Lt {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  align-items: center;
  
}

.ListContainer_ListContainer__124Lt i {
  font-size: 22px;
}

.ListContainer_ListContainer__124Lt p {
  font-size: 19px;
}
.ExploreCreate_ExploreCreate__1mqqI button::before {
  content: 'x';
  color: var(--black);
  font-weight: 300;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.5rem;
  margin-right: 0.7rem;
}

.ExploreCreate_ExploreCreate__1mqqI h3 {
  margin: 1rem 0;
  font-weight: 500;
  font-size: 22px;
}

.ExploreCreate_ExploreCreate__1mqqI .ExploreCreate_ListContainer__1fm0T {
  width: 30%;
  min-width: 300px;
  background-color: var(--white);
  height: auto;
  padding: 1.5rem;
  margin: 2rem 0;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}

@media (max-width: 767px) {
  .ExploreCreate_ExploreCreate__1mqqI .ExploreCreate_ListContainer__1fm0T {
    width: 100%;
    min-width: auto;
  }
}
.TypeAhead_TypeAhead__3Tvsy {
  margin: 0 !important;
  width: 100%;
  position: relative;
}

.TypeAhead_TypeAhead__3Tvsy input {
  width: 175px;
  padding: 2.5px 5px;
  box-sizing: border-box;
  outline-color: var(--primary-blue);
  /* background-color: #66db66 !important; */
}

.TypeAhead_TypeAhead__3Tvsy ul {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: absolute;
  background: var(--white);
  z-index: 10;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 175px;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 200px;
  top: 40px;
}

.TypeAhead_TypeAhead__3Tvsy li{
  padding: 8px 10px;
  cursor: pointer;
  font-size: 0.8rem;
}
  
.TypeAhead_TypeAhead__3Tvsy li:hover{
  background-color: #f1f1f1;
}

.TypeAhead_Active__3bzhF {
  background-color: #f1f1f1;
}
.IngredientsContainer_IngredientsContainer__WlP9e {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}

.IngredientsContainer_IngredientsContainer__WlP9e h4 {
  margin: 2rem 0 0 0 !important;
  font-weight: 600;
  font-size: 16px;
}

.IngredientsContainer_IngredientsContainer__WlP9e .IngredientsContainer_IngredientsWrapper__3JBh3 {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1.2rem 0;
}

.IngredientsContainer_IngredientsContainer__WlP9e .IngredientsContainer_IngredientsWrapper__3JBh3 i {
  margin-left: 0.7rem;
  cursor: pointer;
}

.IngredientsContainer_IngredientsContainer__WlP9e .IngredientsContainer_IngredientsWrapper__3JBh3 .IngredientsContainer_Ingredients__2Q7at {
  width: 100%;
  flex-direction: column;
}

.IngredientsContainer_IngredientsContainer__WlP9e .IngredientsContainer_IngredientsWrapper__3JBh3 .IngredientsContainer_Ingredients__2Q7at input {
  width: 100%;
  margin: 0;
}

.IngredientsContainer_IngredientsContainer__WlP9e .IngredientsContainer_IngredientsWrapper__3JBh3 .IngredientsContainer_Ingredients__2Q7at div {
  display: flex;
  align-items: center;
  margin-top: 0.6rem;
}

.IngredientsContainer_IngredientsContainer__WlP9e .IngredientsContainer_IngredientsWrapper__3JBh3 .IngredientsContainer_Ingredients__2Q7at div label {
  display: inline-block;
  width: 170px;
  font-size: 13px;
}

.IngredientsContainer_IngredientsContainer__WlP9e p {
  color: var(--primary-blue);
  cursor: pointer;
  border: none;
  outline: none;
  margin: 1rem 0 !important;
  background: none;
  font-size: 1rem;
}

.IngredientsContainer_IngredientsContainer__WlP9e p i {
  margin-right: 0.3rem;
}

@media (max-width: 767px) {
  .IngredientsContainer_IngredientsContainer__WlP9e .IngredientsContainer_IngredientsWrapper__3JBh3 .IngredientsContainer_Ingredients__2Q7at > div {
    flex-direction: column;
    align-items: flex-start;
  }

  .IngredientsContainer_IngredientsContainer__WlP9e .IngredientsContainer_IngredientsWrapper__3JBh3 .IngredientsContainer_Ingredients__2Q7at > div label {
    font-size: 14px;
    padding-left: 10px;
    margin-bottom: 8px;
  }
}
.ImageUpload_ImageUpload__1jcTi {
  margin: 1.4rem 0;
  display: flex;
}

.ImageUpload_ImageUpload__1jcTi i {
  margin-right: 1rem;
  font-size: 20px;
  width: 20px;
}

.ImageUpload_ImageUpload__1jcTi div span {
  cursor: pointer;
  color: #828282;
  font-size: 16px;
}

.ImageUpload_ImageUpload__1jcTi div input[type=file] {
  font-size: 14px;
}

.ImageUpload_ImageUpload__1jcTi div input {
  margin: 0 !important;
}

.ImageUpload_ImageUpload__1jcTi .ImageUpload_ImagesContainer__2s8ay {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ImageUpload_ImageUpload__1jcTi .ImageUpload_ImagesContainer__2s8ay div {
  margin: 0 1rem 1rem 0;
  position: relative;
}

.ImageUpload_ImageUpload__1jcTi .ImageUpload_ImagesContainer__2s8ay div img {
  width: 120px;
  height: 90px;
  cursor: pointer;
  z-index: 10;
  box-sizing: border-box;
}

.ImageUpload_ImageUpload__1jcTi .ImageUpload_ImagesContainer__2s8ay div .ImageUpload_CoverImg__1fSZ2 {
  border: 2px solid var(--primary-blue);
}

.ImageUpload_ImageUpload__1jcTi .ImageUpload_ImagesContainer__2s8ay div p {
  display: none;
}

.ImageUpload_ImageUpload__1jcTi .ImageUpload_ImagesContainer__2s8ay div .ImageUpload_CoverTxt__2kb07 {
  display: block;
  font-size: 14px;
  line-height: 16.41px;
  text-align: center;
  color: var(--primary-blue);
}

.ImageUpload_ImageUpload__1jcTi .ImageUpload_ImagesContainer__2s8ay div ul {
  position: absolute;
  top: 90px;
  left: 120px;
  width: 170px;
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1000;
}

.ImageUpload_ImageUpload__1jcTi .ImageUpload_ImagesContainer__2s8ay div ul li {
  list-style-type: none;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  padding: 10px 20px;
  cursor: pointer;
}

.ImageUpload_ImageUpload__1jcTi .ImageUpload_ImagesContainer__2s8ay div ul li:hover {
  background-color: #eee;
}

/*  CSS for delete button
Needs to be deleted possibly
.ImageUpload .ImagesContainer div p {
  margin: 0 !important;
  text-align: center;
  font-size: 12px;
  color: #a10f0f;
  cursor: pointer;
}

.ImageUpload .ImagesContainer div p:hover {
  text-decoration: underline;
} */
.VideoEmbed_VideoEmbed__3somR {
  margin: 1.4rem 0;
  display: flex;
}

.VideoEmbed_VideoEmbed__3somR i {
  margin-right: 1rem;
  font-size: 20px;
  width: 20px;
}

.VideoEmbed_VideoEmbed__3somR p {
  cursor: pointer;
  color: #828282;
  font-size: 16px;
}

.VideoEmbed_VideoEmbed__3somR .VideoEmbed_InputContainer__2LgnM .VideoEmbed_InputWrapper__3upwP {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
}

.VideoEmbed_VideoEmbed__3somR .VideoEmbed_InputContainer__2LgnM .VideoEmbed_InputWrapper__3upwP span {
  margin-right: 10px;
  color: #828282;
}

.VideoEmbed_VideoEmbed__3somR .VideoEmbed_InputContainer__2LgnM .VideoEmbed_InputWrapper__3upwP i {
  margin: 0 0 0 1rem !important;
  cursor: pointer;
  font-size: 17px;
  width: auto;
}

.VideoEmbed_VideoEmbed__3somR .VideoEmbed_InputContainer__2LgnM .VideoEmbed_InputWrapper__3upwP input {
  margin: 0 !important;
  padding: 0.25rem !important;
  width: 250px;
}

.VideoEmbed_VideoEmbed__3somR .VideoEmbed_InputContainer__2LgnM .VideoEmbed_VideoPreviewContainer__1tT3F {
  display: flex;
  flex-wrap: wrap;
}

.VideoEmbed_VideoEmbed__3somR .VideoEmbed_InputContainer__2LgnM .VideoEmbed_VideoPreviewContainer__1tT3F iframe {
  margin: 10px 10px 10px 0;
}

.VideoEmbed_NotValid__1vTGC {
  outline: 2px solid rgb(156, 4, 4);
}

@media (max-width: 767px) {
  .VideoEmbed_VideoEmbed__3somR .VideoEmbed_InputContainer__2LgnM .VideoEmbed_InputWrapper__3upwP input {
    width: auto;
  }
}
.WebsiteBox_WebsiteBox__2Q8Uu {
  margin: 1.4rem 0;
  display: flex;
  width: 100%;
}

.WebsiteBox_WebsiteBox__2Q8Uu i {
  margin-right: 1rem;
  font-size: 20px;
  width: 20px;
}

.WebsiteBox_WebsiteBox__2Q8Uu div {
  width: 100%;
}


.WebsiteBox_WebsiteBox__2Q8Uu div span {
  cursor: pointer;
  color: #828282;
  font-size: 16px;
  min-width: 400px;
  max-width: 100%;
}

.WebsiteBox_WebsiteBox__2Q8Uu div input {
  margin: 0 !important;
  padding: 0.25rem !important;
  width: 100%;
}
.ProjectForm_ProjectForm__1R_q- {
  margin-bottom: 5rem;
  min-width: 150px;
  position: relative;
}

.ProjectForm_ProjectForm__1R_q- h3 {
  font-size: 22px;
  font-weight: 500;
  margin: 1rem 0;
}

.ProjectForm_ProjectForm__1R_q- label span {
  color: #f00;
}

/* Chrome, Safari, Edge, Opera */
.ProjectForm_ProjectForm__1R_q- input::-webkit-outer-spin-button,
.ProjectForm_ProjectForm__1R_q- input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ProjectForm_ProjectForm__1R_q- input[type=number] {
  -moz-appearance: textfield;
}

.ProjectForm_ProjectForm__1R_q- input:disabled,
.ProjectForm_ProjectForm__1R_q- textarea:disabled {
  background: #ddd;
  font-weight: bold;
}

.ProjectForm_ProjectForm__1R_q- input[type=text],
.ProjectForm_ProjectForm__1R_q- input[type=number] {
  height: 34px;
  padding: 1.2rem;
  margin-top: 1.4rem;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: var(--white);
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  box-sizing: border-box;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_FormControl1__3m0jr {
  width: 100%;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_FormControl2__1Itpg {
  width: 60px;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_FormControl3__2I5Dp {
  width: 200px;
}

.ProjectForm_ProjectForm__1R_q- input::-webkit-input-placeholder {
  opacity: 0.7;
}

.ProjectForm_ProjectForm__1R_q- input:-ms-input-placeholder {
  opacity: 0.7;
}

.ProjectForm_ProjectForm__1R_q- input::-ms-input-placeholder {
  opacity: 0.7;
}

.ProjectForm_ProjectForm__1R_q- input::placeholder {
  opacity: 0.7;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_Servings__2iXMF {
  display: flex;
  align-items: center;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_Servings__2iXMF p {
  margin: 1.4rem 10px 0 0;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_InstructionsContainer__2SHPQ {
  margin-bottom: 2rem;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_InstructionsContainer__2SHPQ h4 {
  margin: 1rem 0 !important;
  font-weight: 600;
  font-size: 16px;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_SharingContainer__4aYUF {
  margin-bottom: 2rem;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_SharingContainer__4aYUF .ProjectForm_Privacy__1HGXO i {
  font-size: 20px;
  width: 20px;
  margin-right: 1rem;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_SharingContainer__4aYUF .ProjectForm_Privacy__1HGXO select {
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  padding: 0 !important;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_ButtonContainer__1Gt3z {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_ButtonContainer__1Gt3z button {
  padding: 1rem 5rem;
  border-radius: 10px;
  box-sizing: border-box;
  font-weight: 300;
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: bold;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_ButtonContainer__1Gt3z button:enabled {
  background-color: var(--primary-blue);
  color: var(--white) !important;
  cursor: pointer;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_ButtonContainer__1Gt3z button:hover:enabled {
  background-color: var(--secondary-blue);
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_Disclaimer__3ph5_ > div {
  display: flex;
  align-items: center;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_Disclaimer__3ph5_ > div input {
  width: 40px;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_Disclaimer__3ph5_ > div label {
  display: block;
  margin-left: 5px !important;
}

.ProjectForm_ProjectForm__1R_q- .ProjectForm_Disclaimer__3ph5_ > div label,
.ProjectForm_ProjectForm__1R_q- .ProjectForm_Disclaimer__3ph5_ p {
  font-size: 13px;
  margin: 5px 0;
}

@media (max-width: 767px) {
  .ProjectForm_ProjectForm__1R_q- {
    padding-bottom: 3rem;
  }

  .ProjectForm_ProjectForm__1R_q- .ProjectForm_SharingContainer__4aYUF .ProjectForm_Privacy__1HGXO select option  {
    font-size: 13px;
  }
}
/* Text Editor CSS */
.text-editor .ql-toolbar {
  background-color: #eee;
}

.text-editor .ql-container {
  background-color: var(--white);
}
.ProgressBar_ProgressBar__TWGc7 {
  width: 400px;
  height: 200px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
}

.ProgressBar_ProgressBar__TWGc7 > .ProgressBar_Prog__199IU {
  width: 100%;
  /* border-radius: 50px; */
  position: absolute;
  top: 65%;
  height: 25px;
  background-color: #abb2bc;
  border-radius: 35px;
  -moz-border-radius: 35px;
  -webkit-border-radius: 35px;
  background: -moz-linear-gradient(#9da5b0 0%, #b6bcc6 100%);
  box-shadow: inset 0px 1px 2px 0px rgba(0, 0, 0, 0.5), 0px 1px 0px 0px #FFF;
}

.ProgressBar_ProgressBar__TWGc7 > .ProgressBar_Prog__199IU > div {
  /* height: 100%;
  border-radius: inherit;
  text-align: right;
  transition: width 1s ease-in-out;
  width: 0%; */
  width: 100%;
  position: relative;
  display: block;
  overflow: hidden;
  height: 105%;
  border-radius: 35px;
  background-size: 44px 44px;
  background-color: #026AA4;
  background: -moz-repeating-linear-gradient(top left -30deg,
    rgba(255,255,255,0.17),
    rgba(255,255,255,0.17) 15px,
    rgba(255,255,255,0) 15px,
    rgba(255,255,255,0) 30px
  ), -moz-linear-gradient(#839df3 0%, #839df3 100%);
  animation: ProgressBar_animate-stripes__305Rv 2s linear infinite;
  -webkit-animation: ProgressBar_animate-stripes__305Rv 2s linear infinite;
}

.ProgressBar_ProgressBar__TWGc7 span {
  position: absolute;
  top: 83%;
  font-size: 22px;
  left: 50%;
  transform: translateX(-25%);
  color: #fff;
  font-weight: bold;
}
.CreateProject_CreateProject__1h4Cu {
  width: 50%;
  max-width: 600px;
}

.CreateProject_CreateProject__1h4Cu .CreateProject_DataSpinner__39CYg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.85);
  /* background-color: #e5e5e5; */
}

.CreateProject_CreateProject__1h4Cu .CreateProject_DataSpinner__39CYg p {
  font-size: 20px;
  position: absolute;
  left: 50%;
  top: 10%;
  font-weight: 700;
}
.ModalHeader_ModalHeader__3Q7Dn {
  padding: 10px 0;
  position: relative;
}

.ModalHeader_ModalHeader__3Q7Dn h4 {
  font-size: 20px;
  font-weight: 400;
  padding: 10px 20px;
  display: inline;
  color: var(--primary-blue);
}

.ModalHeader_ModalHeader__3Q7Dn span {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 0 5px;
  cursor: pointer;
}

.ModalHeader_ModalHeader__3Q7Dn span::after {
  content: 'x';
  font-size: 20px;
  color: #f00;
}
.Trip_Trip__1cbvZ {
  height: 600px;
}

.Trip_Block__1VR-I {
  display: block;
}

.Trip_Hide__VlQa_ {
  display: none;
}

.Trip_Selected__2b-Lo {
  font-weight: 700;
  color: #026AA4;
}

.Trip_Selected__2b-Lo::before {
  transform: rotate(90deg);
}

.Trip_ProvBox__3ZaYL {
  background: #d1cfcf;
  height: 550px;
  border: 1px #acacac solid;
  overflow: scroll;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Trip_ProvBox__3ZaYL li:hover {
  background: #e6f6fd;
}

.Trip_ProvBox__3ZaYL li {
  list-style: none;
}

.Trip_ProvBox__3ZaYL li div {
  padding: 15px 0 15px 25px;
}

.Trip_ProvBox__3ZaYL div::before {
  content: "";
  border-color: transparent #111;
  border-style: solid;
  border-width: 0.30em 0 0.30em 0.30em;
  display: block;
  height: 0;
  width: 0;
  left: -13px;
  top: 12px;
  position: relative;
  transition: transform 0.3s;
}

.Trip_ProvBox__3ZaYL div::before.Trip_selected__1AGqR {
  transform: rotate(90deg);
}

.Trip_CityBox__1MXkH {
  background: #e5e4e4;
}

.Trip_CityBox__1MXkH li div {
  padding: 15px 0 15px 50px;
}

.Trip_BrandBox__3BX54 {
  background: #eee;
}

.Trip_BrandBox__3BX54 li div {
  padding: 15px 0 15px 75px;
}

.Trip_StoreBox__1H0F7 {
  background:#fafafa;
}

.Trip_StoreBox__1H0F7 li div {
  padding: 15px 0 15px 100px;
}
.ProjectImages_ProjectImages__37XVi {
  width: 364px;
}

.ProjectImages_ProjectImages__37XVi > .ProjectImages_ImageBox__Kfc0D {
  width: 364px;
  background-color: var(--black);
  box-sizing: border-box;
  border-radius: 10px;
  height: 242.4px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.12);
}

.ProjectImages_ProjectImages__37XVi > .ProjectImages_ImageBox__Kfc0D > img,
.ProjectImages_ProjectImages__37XVi > iframe {
  max-width: 100%;
  height: 242.4px;
  box-sizing: border-box;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.24);
}

.ProjectImages_ProjectImages__37XVi > .ProjectImages_ImageBox__Kfc0D > img {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.ProjectImages_ProjectImages__37XVi > iframe {
  border-radius: 10px;
  width: 364px;
}

.ProjectImages_ProjectImages__37XVi > .ProjectImages_OtherImgs__7ksSi {
  display: flex;
}

.ProjectImages_ProjectImages__37XVi > .ProjectImages_OtherImgs__7ksSi > img,
.ProjectImages_ProjectImages__37XVi > .ProjectImages_OtherImgs__7ksSi > div{
  width: 48px;
  height: 36px;
  margin: 5px 5px 5px 0;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 10px;
  overflow: hidden;
}

.ProjectImages_ProjectImages__37XVi > .ProjectImages_OtherImgs__7ksSi .ProjectImages_Focused__AW91d {
  border: 2px solid var(--primary-blue);
}

.ProjectImages_ProjectImages__37XVi > .ProjectImages_OtherImgs__7ksSi img:hover,
.ProjectImages_ProjectImages__37XVi > .ProjectImages_OtherImgs__7ksSi > div:hover {
  opacity: 0.8;
}

.ProjectImages_ProjectImages__37XVi > .ProjectImages_OtherImgs__7ksSi > div {
  position: relative;
}

.ProjectImages_ProjectImages__37XVi > .ProjectImages_OtherImgs__7ksSi > div img {
  position: absolute;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 10;
}

.ProjectImages_ProjectImages__37XVi > .ProjectImages_OtherImgs__7ksSi > div i {
  z-index: 20;
  position: absolute;
  font-size: 30px;
  opacity: 0.7;
  color: #000;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

@media (min-width: 1500px){
  .ProjectImages_ProjectImages__37XVi {
    width: 455px;
  }

  .ProjectImages_ProjectImages__37XVi > img,
  .ProjectImages_ProjectImages__37XVi > iframe {
    height: 303px;
  }
}

@media (max-width: 768px) {
  .ProjectImages_ProjectImages__37XVi {
    width: 100%;
  }

  .ProjectImages_ProjectImages__37XVi > .ProjectImages_ImageBox__Kfc0D {
    width: 100%;
  }

  .ProjectImages_ProjectImages__37XVi > .ProjectImages_OtherImgs__7ksSi {
    flex-wrap: wrap;
  }
}
.ProjectTitle_HeaderTitle__kGJuJ  {
  padding: 0 1rem;
  display: block;
  width: calc(70% - 364px);
  min-width: 300px;
}

.ProjectTitle_HeaderTitle__kGJuJ h1 {
  font-weight: 500;
  font-size: 21px;
  line-height: 26px;
  margin-bottom: 7px;
}

.ProjectTitle_HeaderTitle__kGJuJ p {
  line-height: 24px;
  color: #031016;
  font-size: 14px;
}

.ProjectTitle_HeaderTitle__kGJuJ a {
  font-size: 14px;
  color: var(--primary-blue);
}

.ProjectTitle_HeaderTitle__kGJuJ .ProjectTitle_MetaTags__3Q7yD {
  margin: 10px 0;
}

.ProjectTitle_HeaderTitle__kGJuJ .ProjectTitle_MetaTags__3Q7yD span {
  text-transform: capitalize;
  display: inline-block;
}

.ProjectTitle_HeaderTitle__kGJuJ .ProjectTitle_MetaTags__3Q7yD span {
  border-radius: 100px;
  border: 1px #031016 solid;
  color: #031016;
  padding: 3px 7px;
  margin-right: 10px;
  font-size: 12px;
  line-height: 16px;
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
}

.ProjectTitle_HeaderTitle__kGJuJ .ProjectTitle_MetaTags__3Q7yD span i {
  margin-right: 7px;
}

@media (max-width: 767px) {
  .ProjectTitle_HeaderTitle__kGJuJ {
    min-width: 100%;
    padding: 0;
    margin: 1em 0;
  }
}
.ProjectActions_ProjectActions__1I3hD .ProjectActions_SecondaryAct__TyNzM > div,
.ProjectActions_ProjectActions__1I3hD .ProjectActions_PrimaryAct__1Ffz8 > div {
  background-color: var(--primary-blue);
  margin-bottom: 1rem;
  padding: 15px 30px;
  border-radius: 10px;
  color: var(--white);
  text-align: center;
  font-size: 12px;
  cursor: pointer;
  box-sizing: border-box;
  width: 124px;
}

.ProjectActions_ProjectActions__1I3hD .ProjectActions_SecondaryAct__TyNzM > div i,
.ProjectActions_ProjectActions__1I3hD .ProjectActions_PrimaryAct__1Ffz8 > div i {
  margin-right: 6px;
  font-size: 18px;
}

.ProjectActions_ProjectActions__1I3hD .ProjectActions_SecondaryAct__TyNzM > .ProjectActions_Selected__1Kp2o {
  background-color: var(--white);
  border: 1px solid var(--primary-blue);
}

.ProjectActions_ProjectActions__1I3hD .ProjectActions_SecondaryAct__TyNzM > .ProjectActions_Selected__1Kp2o > i,
.ProjectActions_ProjectActions__1I3hD .ProjectActions_SecondaryAct__TyNzM > .ProjectActions_Selected__1Kp2o > span {
  color: var(--primary-blue);
}

.ProjectActions_ProjectActions__1I3hD .ProjectActions_PrimaryAct__1Ffz8 div:first-child {
  background-color: var(--primary-blue);
}

.ProjectActions_ProjectActions__1I3hD .ProjectActions_PrimaryAct__1Ffz8 div:nth-child(2) {
  background-color: #AB2C2C;
}

@media (max-width: 767px) {
  .ProjectActions_ProjectActions__1I3hD .ProjectActions_SecondaryAct__TyNzM,
  .ProjectActions_ProjectActions__1I3hD .ProjectActions_PrimaryAct__1Ffz8 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1em 0;
  }

  .ProjectActions_ProjectActions__1I3hD .ProjectActions_SecondaryAct__TyNzM > div,
  .ProjectActions_ProjectActions__1I3hD .ProjectActions_PrimaryAct__1Ffz8 > div {
    margin: 0 10px 0 0;
    padding: 1em 2em;
    width: auto;
  }

  .ProjectActions_ProjectActions__1I3hD .ProjectActions_SecondaryAct__TyNzM > div i,
  .ProjectActions_ProjectActions__1I3hD .ProjectActions_PrimaryAct__1Ffz8 > div i  {
    font-size: 12px;
  }
}
.ProjectHeader_ProjectHeader__3CUtM {
  display: flex;
  margin-top: 1rem;
  width: 100%;
}

@media (max-width: 767px) {
  .ProjectHeader_ProjectHeader__3CUtM {
    flex-direction: column;
  }
}
.ProjectDescription_ProjectDescription__2m67j {
  font-size: 14px;
  margin: 1.5rem 0;
  line-height: 19px;
  width: 60%;
}

@media (max-width: 1200px) {
  .ProjectDescription_ProjectDescription__2m67j {
    width: 80%;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .ProjectDescription_ProjectDescription__2m67j {
    width: 100%;
  }
}
.ProjectComponents_ProjectComponents__1_fpJ {
  margin: 1rem 0;
  width: 60%;
}

.ProjectComponents_ProjectComponents__1_fpJ h4 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  margin-bottom: 10px;
}

.ProjectComponents_ProjectComponents__1_fpJ p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .ProjectComponents_ProjectComponents__1_fpJ {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .ProjectComponents_ProjectComponents__1_fpJ {
    width: 100%;
  }
}
.ProjectInstructions_ProjectInstructions__jqJjP {
  margin: 1rem 0 3rem 0;
  width: 60%;
}

.ProjectInstructions_ProjectInstructions__jqJjP h4 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  margin-bottom: 10px;
}

.ProjectInstructions_ProjectInstructions__jqJjP p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

.ProjectInstructions_ProjectInstructions__jqJjP > div {
  margin: 1rem 0;
}

.ProjectInstructions_ProjectInstructions__jqJjP > div h1,
.ProjectInstructions_ProjectInstructions__jqJjP > div h2,
.ProjectInstructions_ProjectInstructions__jqJjP > div h3 {
  margin: 0.5rem 0;
  font-size: 14px;
  font-weight: 700;
}

.ProjectInstructions_ProjectInstructions__jqJjP > div ol,
.ProjectInstructions_ProjectInstructions__jqJjP > div ul {
  display: block;
  margin: 0 20px;
}

.ProjectInstructions_ProjectInstructions__jqJjP > div ol li,
.ProjectInstructions_ProjectInstructions__jqJjP > div ul li {
  margin-bottom: 1rem;
}

.ProjectInstructions_ProjectInstructions__jqJjP > div ol li,
.ProjectInstructions_ProjectInstructions__jqJjP > div ul li,
.ProjectInstructions_ProjectInstructions__jqJjP > div p {
  font-size: 14px;
  line-height: 19px;
}

@media (max-width: 1200px) {
  .ProjectInstructions_ProjectInstructions__jqJjP {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .ProjectInstructions_ProjectInstructions__jqJjP {
    width: 100%;
  }

  .ProjectInstructions_ProjectInstructions__jqJjP > div p {
    margin-bottom: 5px;
  }
}
.ProjectDetails_ProjectDetails__1nF9w {
  width: calc(100vw - 300px);
}

/* @media(min-width: 1500px){
  .ProjectDetails {
    width: 40%;
  }
} */

@media (max-width: 768px) {
  .ProjectDetails_ProjectDetails__1nF9w {
    width: 100%;
  }
}
:root {
  --primary-blue: #026AA4;
  --secondary-blue: #4381c4;
  --positive-green: #237B0C;
  --dark-grey: #828282;
  --small-label: #798B95;
  --deep-blue: #0C3E59;
  --white: #fff;
  --black: #1D1D1D;
  --red: #AB2C2C;
  --light-grey: #D9D9D9;
}

* {
  margin: 0;
  padding: 0;
}

html { height: 100%; }

label { display: block; }

button:active {
  transform: scale(0.9, 0.9);
}

body {
  height: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  background-color: #fafafa;
  padding:  env(safe-area-inset-top)
            env(safe-area-inset-right, 20px)
            env(safe-area-inset-bottom, 20px)
            env(safe-area-inset-left, 20px);
}

body.modal-open {
  overflow: hidden;
}

div#root {
  height: 100%;
  overflow-y: scroll;
}

hr {
  margin: 1rem 0;
  border-color: #e5e5e5;
  opacity: 0.2;
}

.container {
  margin-left: 200px;
}

.padded{
  padding: 5rem 3rem 3rem 3rem;
  box-sizing: border-box;
}

.no-link {
  color: black;
  text-decoration: none;
  margin-bottom: 1rem;
}

.bt-back {
  color: black;
  outline: none;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
}

.block {
  display: block;
}

.selected {
  color: #026AA4!important;
  font-weight: bold;
}

.selected i {
  background: #026AA4;
  color: #fff;
}

.activeTab {
  font-weight: bold;
  border-bottom: 2px #346ba4 solid;
  color: #346ba4;
}

.react-slidedown{
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;    
}

div#imagePreloadContainer{
  display: none;
}

/* Landing Global Styles */
.ld-container {
  width: 75%;
  margin: 0 auto;
}

.fa.fa-angle-right {
  font-size: 18px;
}

@media only screen
and (min-width: 320px)
and (max-width: 420px) {
  .padded{
    padding: 3rem 1rem;
  }

  .container {
    margin: 0;
    width: 100%;
  }

  .selected {
    color: #000 !important;
    font-weight: 500;
  }

  .selected i {
    background: #fff;;
    color: #000!important;
  }
}

@media (min-width: 1500px){
  .ld-container{
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .padded {
    padding: 5rem 1.5rem;
  }
}

@media (max-width: 767px) {
  .padded {
    padding: 2rem 1rem 5rem 1rem;
  }

  .container {
    margin: 0;
    width: 100%;
  }

  .selected {
    color: #000 !important;
    font-weight: 500;
  }

  .selected i {
    background: #fff;;
    color: #000!important;
  }

  .grecaptcha-badge {
    bottom: 70px!important;
    display: none !important;
  }
}

/* Printing will initiate in Landscape mode */
@media print{
  @page {size: landscape}
  body * {
    visibility: hidden;
  }

  .container {
    margin: 0;
    padding: 0;
  }

  #store, #store * {
    visibility: visible;
  }

  #store {
    flex-direction: column;
  }
}


@supports(padding:max(0px)) {
  body, header, footer {
      padding:  env(safe-area-inset-top)
                env(safe-area-inset-right, 20px)
                env(safe-area-inset-bottom, 20px)
                env(safe-area-inset-left, 20px);
  }
}
