.ProximityContainer > h2 {
  font-weight: 500;
  margin-top: 2rem;
}

.ProximityContainer > p {
  margin-top: 2rem;
}

.SearchByLocation {
  width: 60%;
  margin-top: 2rem;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

.LocationWrapper {
  flex-basis: 48%;
  min-width: 300px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 1rem 0;
  padding: 1.5rem 1rem;
  background: var(--white);
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}

@media (max-width: 1200px) {
  .SearchByLocation {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .SearchByLocation {
    width: 100%;
  }

  .LocationWrapper {
    width: 100%;
    flex-basis: 100%;
  }
}