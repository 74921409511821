@import url('https://fonts.googleapis.com/css2?family=Roboto &display=swap');

:root {
  --primary-blue: #026AA4;
  --secondary-blue: #4381c4;
  --positive-green: #237B0C;
  --dark-grey: #828282;
  --small-label: #798B95;
  --deep-blue: #0C3E59;
  --white: #fff;
  --black: #1D1D1D;
  --red: #AB2C2C;
  --light-grey: #D9D9D9;
}

* {
  margin: 0;
  padding: 0;
}

html { height: 100%; }

label { display: block; }

button:active {
  transform: scale(0.9, 0.9);
}

body {
  height: 100%;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  background-color: #fafafa;
  padding:  env(safe-area-inset-top)
            env(safe-area-inset-right, 20px)
            env(safe-area-inset-bottom, 20px)
            env(safe-area-inset-left, 20px);
}

body.modal-open {
  overflow: hidden;
}

div#root {
  height: 100%;
  overflow-y: scroll;
}

hr {
  margin: 1rem 0;
  border-color: #e5e5e5;
  opacity: 0.2;
}

.container {
  margin-left: 200px;
}

.padded{
  padding: 5rem 3rem 3rem 3rem;
  box-sizing: border-box;
}

.no-link {
  color: black;
  text-decoration: none;
  margin-bottom: 1rem;
}

.bt-back {
  color: black;
  outline: none;
  background: none;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
}

.block {
  display: block;
}

.selected {
  color: #026AA4!important;
  font-weight: bold;
}

.selected i {
  background: #026AA4;
  color: #fff;
}

.activeTab {
  font-weight: bold;
  border-bottom: 2px #346ba4 solid;
  color: #346ba4;
}

.react-slidedown{
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;    
}

div#imagePreloadContainer{
  display: none;
}

/* Landing Global Styles */
.ld-container {
  width: 75%;
  margin: 0 auto;
}

.fa.fa-angle-right {
  font-size: 18px;
}

@media only screen
and (min-width: 320px)
and (max-width: 420px) {
  .padded{
    padding: 3rem 1rem;
  }

  .container {
    margin: 0;
    width: 100%;
  }

  .selected {
    color: #000 !important;
    font-weight: 500;
  }

  .selected i {
    background: #fff;;
    color: #000!important;
  }
}

@media (min-width: 1500px){
  .ld-container{
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .padded {
    padding: 5rem 1.5rem;
  }
}

@media (max-width: 767px) {
  .padded {
    padding: 2rem 1rem 5rem 1rem;
  }

  .container {
    margin: 0;
    width: 100%;
  }

  .selected {
    color: #000 !important;
    font-weight: 500;
  }

  .selected i {
    background: #fff;;
    color: #000!important;
  }

  .grecaptcha-badge {
    bottom: 70px!important;
    display: none !important;
  }
}

/* Printing will initiate in Landscape mode */
@media print{
  @page {size: landscape}
  body * {
    visibility: hidden;
  }

  .container {
    margin: 0;
    padding: 0;
  }

  #store, #store * {
    visibility: visible;
  }

  #store {
    flex-direction: column;
  }
}


@supports(padding:max(0px)) {
  body, header, footer {
      padding:  env(safe-area-inset-top)
                env(safe-area-inset-right, 20px)
                env(safe-area-inset-bottom, 20px)
                env(safe-area-inset-left, 20px);
  }
}