.LoginContainer {
  width: 50%;
  max-width: 600px;
}

.DataSpinner {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(185, 184, 184, 0.5);
}

.TabsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Tabs {
  flex-basis: 50%;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e8e8e8;
  text-align: center;
  outline: none;
}

.FormContainer .FormHeader h4 {
  font-size: 18px;
  font-weight: 500;
}

.FormContainer .FormHeader {
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.FormContainer .FormBody {
  padding: 15px 0;
}

.FormContainer .FormBody form .NameContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.FormContainer .FormBody form .NameContainer div {
  flex-basis: 49%;
  min-width: 150px;
}

.FormContainer .FormBody form div {
  position: relative;
}

.FormContainer .FormBody form div i {
  opacity: 0.7;
  font-size: 12px;
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}

.FormFooter {
  padding: 15px 0;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.FormFooter a {
  color: #1B77F2;
  text-decoration: none;
}

.FormFooter a:hover {
  text-decoration: underline;
}

.FormFooter button{
  margin-left: 5px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 6px 12px;
  border-radius: 4px;
}

.FormFooter button:first-child{
  text-shadow: 0 1px 0 #fff;
  background-image: -webkit-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: -o-linear-gradient(top,#fff 0,#e0e0e0 100%);
  background-image: linear-gradient(to bottom,#fff 0,#e0e0e0 100%);
  background-repeat: repeat-x;
  border-color: #dbdbdb;
  border-color: #ccc;
}

.FormFooter button:first-child:hover{
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.FormFooter button:last-child {
  background-color: var(--primary-blue);
  color: var(--white);
}

.FormFooter button:last-child:hover {
  color: var(--white);
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);
}

@media (max-width: 1024px) {
  .LoginContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .LoginContainer {
    width: 100%;
  }

  .FormContainer .FormBody {
    padding: 15px 0 0 0;
  }

  .FormFooter {
    flex-direction: column;
  }

  .FormFooter a {
    order: 2;
  }

  .FormFooter > div {
    order: 1;
    width: 100%;
  }

  .FormFooter button {
    display: block;
    width: 100%;
    margin-bottom: 7px;
    height: 40px;
    margin-left: 0;
  }

  .FormFooter > div {
    display: flex;
    flex-direction: column;
  }

  .FormFooter button:first-child {
    order: 2;
  }

  .FormFooter button:last-child {
    order: 1;
  }
}