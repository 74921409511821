.StoreHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  position: absolute;
  width: 100%;
  z-index: 10;
  background: #fff;
  top: 30px;
  height: 70px;
  box-sizing: border-box;
}

.StoreHeader .CloseBtn {
  width: 10%;
  padding-left: 10px;
  box-sizing: border-box;
  text-decoration: none;
  color: #000;
}

.StoreHeader .CloseBtn:after {
  content: "\00d7"; /* This will render the 'X' */
  font-size: 2rem;
}

.StoreHeader p {
  width: 65%;
  text-align: center;
}

.StoreHeader .AddToTrip {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 25%;
  color: #016BA4;
}

.StoreHeader .AddToTrip p {
  width: 100%;
  text-align: center;
}