.ControlsContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 1rem 0 2rem 0;
}

.ControlsContainer .Date {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.ControlsContainer .Date input {
  padding: 0.25rem;
}

.ControlsContainer .Date i {
  cursor: pointer;
  margin-left: 1rem;  
}

.ControlsContainer .Controls {
  display: flex;
  justify-content: flex-end;
}

.ControlsContainer .Controls div {
  cursor: pointer;
  display: flex;
  margin-left: 2rem;
}

.ControlsContainer .Controls p {
  margin-left: 0.5rem;
}

@media only screen and (max-width: 767px) {
  .ControlsContainer {
    flex-direction: column;
  }

  .ControlsContainer .Date {
    margin-bottom: 1rem;
  }

  .ControlsContainer .Controls {
    width: 100%;
    justify-content: space-between;
  }

  .ControlsContainer .Controls div {
    margin: 0;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .ControlsContainer .Date h3 {
    font-size: 1rem;
  }

  .ControlsContainer .Controls p {
    font-size: 0.8rem;
  }
}