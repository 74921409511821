.ChildContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.StoreDetails {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 50px;
}

.StoreDetails img{
  width: 90px;
  max-height: 45px;
  padding-right: 1rem;
}

.StoreDetails div {
  line-height: 1.3rem;
}

.StoreDetails p {
  color: var(--dark-grey);
  font-size: 0.9rem;
  opacity: 0.9;
}

.ChildContainer i {
  margin-left: 1rem;
}

@media only screen and (max-width: 767px) {
  .StoreDetails img {
    width: 120px;
  }

  .StoreDetails div {
    line-height: 1.5rem;
  }

  .StoreDetails h4 {
    font-size: 18px;
    line-height: normal;
  }

  .StoreDetails p {
    font-size: 0.9rem;
    line-height: normal;
  }
}