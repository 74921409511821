.FilterStore {
  width: 60%;
  min-width: 300px;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 2rem 0;
}

.StoreContainer {
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  flex-basis: 50%;
}

.StoreContainer h3 {
  font-weight: 500;
  font-size: 1.4rem;
}

.StoreWrapper {
  width: 95%;
  min-width: 300px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 1rem 0;
  padding: 1.5rem 1rem;
  background: var(--white);
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
  min-height: 100px;
}

@media (max-width: 1200px) {
  .FilterStore {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .FilterStore {
    width: 100%;
  }

  .StoreContainer {
    flex-basis: 100%;
  }

  .StoreWrapper {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .FilterStore {
    width: 100%;
  }
}