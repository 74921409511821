.ModalHeader {
  padding: 10px 0;
  position: relative;
}

.ModalHeader h4 {
  font-size: 20px;
  font-weight: 400;
  padding: 10px 20px;
  display: inline;
  color: var(--primary-blue);
}

.ModalHeader span {
  position: absolute;
  right: 10px;
  top: 5px;
  padding: 0 5px;
  cursor: pointer;
}

.ModalHeader span::after {
  content: 'x';
  font-size: 20px;
  color: #f00;
}