.Landing {
  width: 100%;
  position: relative;
  top: 4rem;
}

/* Banner */
.Banner {
  z-index: 200;
  min-height: 56px;
  width: 100%;
  background: var(--positive-green);
  position: fixed;
}

.Banner > div {
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
}

.Banner > div span p,
.Banner > div span a {
  font-size: 13px;
  display: inline;
  color: var(--white);
}

.Banner > div .CloseBtn:after {
  content: "\00d7"; /* This will render the 'X' */
  font-size: 26px;
  color: var(--white);
  cursor: pointer;
}

/* First Section Styles (Find Stores) */
.FindStores {
  height: calc(100vh - 10rem);
  position: relative;
  display: flex;
  align-items: center
}

.FindStores .LocationContainer {
  width: 625px;
  min-width: 625px;
  background-color: var(--white);
  padding: 3rem 3rem;
  box-sizing: border-box;
  margin: 5rem 0;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  z-index: 10;
  margin-top: -5rem;
}

.FindStores .LocationContainer h2 {
  font-size: 1.8rem;
  margin-bottom: 2rem;
}

.FindStores .LocationContainer h4 {
  color: var(--small-label);
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  font-weight: normal;
  margin-bottom: 2rem;
}

.FindStores .LocationContainer form {
  border: none;
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  height: 60px;
}

.FindStores .LocationContainer form input[type=text] {
  outline: none;
  border: 2px solid var(--primary-blue);
  width: 70%;
  background-color: #F1FAFF;
  color: var(--primary-blue);
  padding: 1.5rem;
  border-top-left-radius: 35px;
  border-bottom-left-radius: 35px;
  box-sizing: border-box;
  font-size: 0.8rem;
  height: 100%;
  text-align: center;
}

.LocationContainer form input[type=text]::placeholder {
  color: var(--primary-blue);
}

.FindStores .LocationContainer form button {
  width: 30%;
  outline: none;
  background-color: var(--primary-blue);
  border: none;
  box-sizing: border-box;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  height: 100%;
  font-size: 0.8rem;
  color: var(--white);
  font-weight: normal;
  cursor: pointer;
}

.LocationContainer form button i {
  margin-right: 0.7rem;
  font-size: 1rem;
}

.LocationContainer .FindMyLoc {
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.LocationContainer .FindMyLoc p {
  color: var(--black);
  margin: 0 2rem 0 0;
}

.LocationContainer .FindMyLoc button {
  outline: none;
  background: none;
  border: none;
  color: var(--primary-blue);
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.LocationContainer .FindMyLoc button i {
  margin-right: 5px;
  font-size: 1.2rem;
}

.FindStores img {
  width: 500px;
  position: absolute;
  bottom: 15%;
  left: 500px;
}

/* Second Section Styles (Application Overview) */
.Overview {
  width: 100%;
  background-color: var(--white);
}

.OverviewContainer {
  display: flex;
  align-items: center;
  flex-flow: wrap;
}

.OverviewContainer .OverviewDescription {
  flex-basis: 50%;
  min-width: 400px;
  padding: 2rem 0;
  box-sizing: border-box;
  margin: 5rem 0;
}

.OverviewContainer div {
  flex-basis: 50%;
  min-width: 400px;
}

.OverviewContainer .OverviewDescription h2 {
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: 1.5rem;
  font-size: 1.7rem;
}

.OverviewContainer .OverviewDescription p {
  color: var(--small-label);
  line-height: 1.7rem;
  font-size: 1.1rem;
}

.OverviewContainer img {
  width: 100%;
  margin: 5rem 0;
}

/* Section 3 - Stores Advertisement */
.Stores {
  height: calc(100vh - 7.5rem);
}

.Stores h2 {
  text-transform: uppercase;
  font-style: italic;
  width: 50%;
  margin: 0 auto;
  min-width: 400px;
  font-size: 1.8rem;
  padding-top: 5rem;
  text-align: center;
  line-height: 2.7rem;
}

.Stores .StoresContainer {
  display: flex;
  flex-flow: row wrap;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
}

.Stores .StoreImage {
  flex-basis: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4rem 0;
}

.Stores .StoreImage img {
  width: 50%;
}

/* Apply to be a Mapper  */
.MapperApplication {
  width: 100%;
  background: var(--white);
  height: 487px;
  box-sizing: content-box;
  margin: 0;
}

.MapperApplication img {
  display: none;
}

.MapperApplication .Wrapper {
  background: linear-gradient(90deg, #FFFFFF 11.4%, rgba(255, 255, 255, 0) 40.66%), url('../../../img/bg-mapper.png');
  background-repeat: no-repeat;
  height: 100%;
  margin-left: 12.5%;
  background-size: 70% 100%;
  background-position: right;
}

.MapperApplication .Wrapper > div {
  width: 501px;
  padding-top: 100px;
}

.MapperApplication .Wrapper > div h2 {
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: 1.5rem;
  font-size: 1.7rem;
  color: var(--black);
}

.MapperApplication .Wrapper > div p {
  color: var(--small-label);
  line-height: 1.7rem;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}

.MapperApplication .Wrapper > div a {
  text-decoration: none;
  color: var(--primary-blue);
  size: 16px;
  font-weight: 400;
  border: 2px solid var(--primary-blue);
  padding: 17px 23px;
  background-color: var(--white);
  display: inline-block;
  text-transform: uppercase;
  border-radius: 7px;
}

.MapperApplication .Wrapper > div a:hover {
  background-color: var(--primary-blue);
  color: var(--white);
  transition: background-color 300ms linear;
  -webkit-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  transition: color 300ms linear;
  -webkit-transition: color 300ms linear;
  -ms-transition: color 300ms linear;
}

/* Section 4 - Mobile app ad - On the go */
.MobileApp {
  height: calc(100vh - 4rem);
  background-color: var(--primary-blue);
  width: 100%;
}

.MobileApp .MobileWrapper {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.MobileApp .InnerCircleContainer {
  width: 50%;
  min-width: 400px; 
}

.MobileApp .InnerCircle {
  z-index: 2;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  background-color: #F1C94C;
  position: relative;
}

.MobileApp .InnerCircle img {
  width: 220px;
  position: absolute;
  left: 50%;
  top: -10%;
  transform: translateX(-50%);
  border-radius: 15px;
}

.MobileApp .Description {
  width: 50%;
  min-width: 400px;
}

.MobileApp .Description h1 {
  color: white;
  font-style: italic;
  text-transform: uppercase;
}

.MobileApp .Description p {
  color: #E0E0E0;
  font-size: 1.1rem;
  line-height: 2rem;
  margin: 2rem 0;
}

.MobileApp .Description img {
  width: 150px;
  margin-right: 1.5rem;
}


/* Footer Section */
.Footer {
  width: 100%;
  background-color: var(--deep-blue);
  padding: 5rem 5rem;
  box-sizing: border-box;
}

.Footer div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Footer p {
  color: var(--light-grey);
}

.Footer a,
.Footer p:last-child {
  color: var(--white);
  text-decoration: none;
  cursor: pointer;
}

@media (min-width: 1500px) {
  .MapperApplication .Wrapper {
    margin-left: 20%;
  }
}

/* Media Queries */
/* Mobile Phone */
@media (max-width: 767px){
  .Banner {
    bottom: 0;
    height: fit-content;
  }

  .Banner > div {
    height: fit-content;
    padding: 0.5rem 0;
  }

  /* First Section Styles (Find Stores) */
  .FindStores {
    height: auto;
    top: 2rem;
    padding: 2rem 0;
  }

  .FindStores .LocationContainer {
    width: 100%;
    padding: 1.5rem;
    min-width: auto;
    margin: 6rem 0;
  }

  .FindStores .LocationContainer h2 {
    font-size: 1.4rem;
  }

  .FindStores .LocationContainer h4 {
    font-size: 1rem;
  }

  .FindStores .LocationContainer form {
    width: 100%;
  }

  .FindStores .LocationContainer form input[type=text] {
    padding: 0.75rem;
    font-size: 0.75rem;
    height: 40px;
  }

  .FindStores .LocationContainer form button {
    height: 40px;
    padding: 0.75rem;
  }

  .LocationContainer form button span {
    display: none;
  }

  .FindStores img {
    width: 110%;
    top: 45%;
    left: -10px;
  }

  /* Second Section Styles (Application Overview) */
  .OverviewContainer .MobileOrder {
    order: 2;
  }
  
  .OverviewContainer .OverviewDescription {
    width: 100%;
    min-width: auto;
    flex-basis: 100%;
    margin: 2rem 0;
    text-align: center;
  }

  .OverviewContainer div {
    flex-basis: 100%;
    min-width: auto;
  }

  .OverviewContainer div img {
    margin: 1rem 0;
  }

  /* Section 3 - Stores Advertisement */
  .Stores {
    height: auto;
  }

  .Stores h2 {
    width: 100%;
    min-width: auto;
    font-size: 1.5rem;
  }

  .Stores .StoreImage {
    flex-basis: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Stores .StoreImage img {
    width: 80%;
  }

  /* Apply to be a mapper */
  .MapperApplication {
    height: auto;
  }

  .MapperApplication img {
    display: block;
    width: 100%;
    height: 256px;
  }

  .MapperApplication .Wrapper {
    background: var(--white);
    margin: 0 12.5%;
    text-align: center;
  }

  .MapperApplication .Wrapper > div {
    padding: 100px 0;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  /* Section 4 - Mobile app ad - On the go */
  .MobileApp {
    height: auto;
  }

  .MobileApp .InnerCircleContainer {
    width: 100%;
    min-width: auto;
    margin: 5rem 0;
  }

  .MobileApp .InnerCircle {
    z-index: 2;
    width: 100%;
    height: auto;
    padding-top: 100%;
    border-radius: 50%;
    background-color: #F1C94C;
  }

  .MobileApp .InnerCircle img {
    width: 160px;
  }

  .MobileApp .Description {
    width: 100%;
    margin-bottom: 5rem;
    min-width: auto;
  }

  .MobileApp .Description h1 {
    text-align: center;
  }

  .MobileApp .Description p {
    text-align: center;
  }

  .MobileApp .Description div {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
  }

  .MobileApp .Description img {
    margin: 1rem 0;
  }

  /* Footer Section */
  .Footer {
    padding: 5rem 3rem;
  }

  .Footer div {
    flex-direction: column;
  }

  .Footer div p,
  .Footer div a {
    margin-bottom: 1.5rem;
    font-size: 1.1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .Overview .OverviewContainer {
    justify-content: center;
  }

  .Overview .OverviewContainer .OverviewDescription {
    margin: 3rem 0;
  }

  .Stores {
    height: auto;
  }

  .MobileApp {
    height: auto;
    padding: 5rem 0;
  }

  .MobileApp .MobileWrapper {
    justify-content: center;
  }

  .MobileApp .MobileWrapper .InnerCircleContainer {
    margin-bottom: 5rem;
  }

  .MobileApp .Description {
    margin-top: 5rem;
    text-align: center;
  }

  .Footer {
    padding: 5rem 3rem;
  }

  .Footer div {
    flex-direction: column;
  }

  .Footer div p,
  .Footer div a {
    margin-bottom: 1.5rem;
    font-size: 1.2rem;
  }
}