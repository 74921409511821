.SearchBrand {
  width: 60%;
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 2rem 0;
}

.BrandContainer {
  flex-basis: 48%;
  min-width: 300px;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 1rem 0;
  padding: 1rem;
  background: var(--white);
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}


@media only screen and (max-width: 1350px) {
  .SearchBrand {
    width: 80%;
  }
}

@media only screen and (max-width: 767px) {
  .SearchBrand {
    width: 100%;
  }

  .BrandContainer {
    width: 100%;
    flex-basis: 100%;
    padding: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .BrandContainer {
    min-width: auto;
    flex-basis: 47%;
  }

  .SearchBrand {
    width: 100%;
  }
}