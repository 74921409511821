.ProjectForm {
  margin-bottom: 5rem;
  min-width: 150px;
  position: relative;
}

.ProjectForm h3 {
  font-size: 22px;
  font-weight: 500;
  margin: 1rem 0;
}

.ProjectForm label span {
  color: #f00;
}

/* Chrome, Safari, Edge, Opera */
.ProjectForm input::-webkit-outer-spin-button,
.ProjectForm input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.ProjectForm input[type=number] {
  -moz-appearance: textfield;
}

.ProjectForm input:disabled,
.ProjectForm textarea:disabled {
  background: #ddd;
  font-weight: bold;
}

.ProjectForm input[type=text],
.ProjectForm input[type=number] {
  height: 34px;
  padding: 1.2rem;
  margin-top: 1.4rem;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: var(--white);
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  box-sizing: border-box;
}

.ProjectForm .FormControl1 {
  width: 100%;
}

.ProjectForm .FormControl2 {
  width: 60px;
}

.ProjectForm .FormControl3 {
  width: 200px;
}

.ProjectForm input::placeholder {
  opacity: 0.7;
}

.ProjectForm .Servings {
  display: flex;
  align-items: center;
}

.ProjectForm .Servings p {
  margin: 1.4rem 10px 0 0;
}

.ProjectForm .InstructionsContainer {
  margin-bottom: 2rem;
}

.ProjectForm .InstructionsContainer h4 {
  margin: 1rem 0 !important;
  font-weight: 600;
  font-size: 16px;
}

.ProjectForm .SharingContainer {
  margin-bottom: 2rem;
}

.ProjectForm .SharingContainer .Privacy i {
  font-size: 20px;
  width: 20px;
  margin-right: 1rem;
}

.ProjectForm .SharingContainer .Privacy select {
  border: none;
  background: none;
  outline: none;
  font-size: 16px;
  padding: 0 !important;
}

.ProjectForm .ButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5rem 0;
}

.ProjectForm .ButtonContainer button {
  padding: 1rem 5rem;
  border-radius: 10px;
  box-sizing: border-box;
  font-weight: 300;
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
  font-size: 14px;
  font-weight: bold;
}

.ProjectForm .ButtonContainer button:enabled {
  background-color: var(--primary-blue);
  color: var(--white) !important;
  cursor: pointer;
}

.ProjectForm .ButtonContainer button:hover:enabled {
  background-color: var(--secondary-blue);
}

.ProjectForm .Disclaimer > div {
  display: flex;
  align-items: center;
}

.ProjectForm .Disclaimer > div input {
  width: 40px;
}

.ProjectForm .Disclaimer > div label {
  display: block;
  margin-left: 5px !important;
}

.ProjectForm .Disclaimer > div label,
.ProjectForm .Disclaimer p {
  font-size: 13px;
  margin: 5px 0;
}

@media (max-width: 767px) {
  .ProjectForm {
    padding-bottom: 3rem;
  }

  .ProjectForm .SharingContainer .Privacy select option  {
    font-size: 13px;
  }
}