.SearchStore p {
  color: var(--dark-grey);
  font-size: 0.9rem;
  opacity: 0.9;
}

.StoreContainer {
  width: 60%;
  min-width: 600px;
  background-color: var(--white);
  height: auto;
  padding: 1.5rem;
  margin: 2rem 0;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}

@media only screen and (max-width: 767px) {
  .StoreContainer {
    min-width: auto;
    width: auto;
    padding: 1rem 1.5rem;
  }

  .SearchStore form {
    margin-bottom: 5rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .StoreContainer {
    width: 100%;
    min-width: auto;
  }
}