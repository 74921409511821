.IngredientsContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}

.IngredientsContainer h4 {
  margin: 2rem 0 0 0 !important;
  font-weight: 600;
  font-size: 16px;
}

.IngredientsContainer .IngredientsWrapper {
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1.2rem 0;
}

.IngredientsContainer .IngredientsWrapper i {
  margin-left: 0.7rem;
  cursor: pointer;
}

.IngredientsContainer .IngredientsWrapper .Ingredients {
  width: 100%;
  flex-direction: column;
}

.IngredientsContainer .IngredientsWrapper .Ingredients input {
  width: 100%;
  margin: 0;
}

.IngredientsContainer .IngredientsWrapper .Ingredients div {
  display: flex;
  align-items: center;
  margin-top: 0.6rem;
}

.IngredientsContainer .IngredientsWrapper .Ingredients div label {
  display: inline-block;
  width: 170px;
  font-size: 13px;
}

.IngredientsContainer p {
  color: var(--primary-blue);
  cursor: pointer;
  border: none;
  outline: none;
  margin: 1rem 0 !important;
  background: none;
  font-size: 1rem;
}

.IngredientsContainer p i {
  margin-right: 0.3rem;
}

@media (max-width: 767px) {
  .IngredientsContainer .IngredientsWrapper .Ingredients > div {
    flex-direction: column;
    align-items: flex-start;
  }

  .IngredientsContainer .IngredientsWrapper .Ingredients > div label {
    font-size: 14px;
    padding-left: 10px;
    margin-bottom: 8px;
  }
}