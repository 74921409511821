.StoreTabs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 100px;
  z-index: 18;
}

.Tabs {
  flex-basis: 50%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid #e8e8e8;
  background-color: #fff;
  text-align: center;
  outline: none;
}