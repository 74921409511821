.ProjectDescription {
  font-size: 14px;
  margin: 1.5rem 0;
  line-height: 19px;
  width: 60%;
}

@media (max-width: 1200px) {
  .ProjectDescription {
    width: 80%;
    margin: 0;
  }
}

@media (max-width: 768px) {
  .ProjectDescription {
    width: 100%;
  }
}