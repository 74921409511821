.CurrentTrips {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.CurrentTrips .TripsContainer {
  margin-bottom: 2rem;
  width: 100%;
}

.TripBox {
  margin-bottom: 7rem;
}

.CurrentTrips .TripsContainer .Trips {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px #d6d6d6 solid;
  width: 100%;
  background-color: #fff;
  padding: 1rem;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}

.CurrentTrips .TripsContainer .Trips:hover {
  background-color: #fdf7e7;
}

.Trips .TripDetailsArchive,
.Trips .TripDetails {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.TripDetails {
  flex-basis: 70%;
}

.TripDetailsArchive {
  flex-basis: 100%;
}


.Trips .TripDetails img,
.Trips .TripDetailsArchive  img {
  width: 75px;
  max-height: 45px;
  padding-right: 1rem;
}

.Trips .TripDetails span,
.Trips .TripDetailsArchive span {
  color: var(--dark-grey);
  font-size: 0.85rem;
}

.Trips .TripDetails p,
.Trips .TripDetailsArchive p {
  margin-top: 0.5rem;
  font-size: 0.85rem;
}

.Trips button {
  color: #AB2C2C;
  outline: none;
  border: 1px #AB2C2C solid;
  background: #FAE8E8;
  padding: 0.5rem 1rem;
  border-radius: 15px;
  cursor: pointer;
  display: none;
  transition: all 0.2s;
}

.Trips button:hover {
  background: #AB2C2C;
  color: #fff;
  border: 1px #000 solid;
  padding: 0.7rem 1.2rem;
  border-radius: 20px;
}

.Trips:hover button {
  display: block;
}

.CurrentTrips .AddStore {
  background-color: #346BA4;
  color: #fff !important;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-sizing: border-box;
  font-weight: 300;
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
}

.CurrentTrips .AddStore:hover {
  background-color: rgb(67, 129, 196);
}

.NoTrips {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.NoTrips p:first-child {
  margin-top: 3rem;
}

.NoTrips p {
  margin-top: 1rem
}

/* Mobile @media */
@media only screen and (max-width: 767px) {
  .AddStore {
    position: absolute;
    bottom: 10%;
    width: 90%;
  }

  .NoTrips p:first-child {
    margin-top: 6rem;
  }

  .Trips button {
    padding: 0.5rem 0.5rem;
  }

  .Trips button:hover {
    color: #AB2C2C;
    outline: none;
    border: 1px #AB2C2C solid;
    background: #FAE8E8;
    padding: 0.5rem 1rem;
    border-radius: 15px;
  }

  .Trips button:active {
    display: block;
  }

  .Trips .TripDetails {
    flex-basis: 60%;
  }
}