.QuickAdd {
  width: 100%;
  margin-bottom: 2rem;
  position: relative;
}

.QuickAdd .CloseBtn {
  position: absolute;
  right: 1rem;
  cursor: pointer;
  display: none;
}

.QuickAdd .CloseBtn:after{
  content: "\00d7"; /* This will render the 'X' */
  font-size: 2rem;
}

.QuickAdd:hover .CloseBtn {
  display: block;
}

.QuickAdd h4 {
  display: flex;
  align-items: center;
}

.QuickAdd h4 span {
  width:0;
  height:0;
  border-left: 6px solid var(--black);
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  transition: transform 0.25s ease-in-out;
  margin-left: 0.5rem;
  cursor: pointer;
}

.QuickAdd h4 span:hover {
  border-left: 8px solid var(--secondary-blue);
}

span.Rotated{
  transform : rotate(90deg); 
}

.QuickAdd .Controls {
  display: flex;
}

.QuickAdd .Controls p {
  font-size: 0.8rem;
  text-decoration: underline;
  color: #346BA4;
  cursor: pointer;
  margin-right: 0.75rem;
}

.QuickAdd .AddInput {
  display: flex;
  flex-direction: row;
}

.QuickAdd .AddInput button {
  margin-left: 0.5rem;
  padding: 0 0.5rem;
  height: 24px;
}

.ItemContainer {
  display: flex;
  justify-content: flex-start;
  margin: 1rem 0;
  cursor: pointer;
  width: fit-content;
  align-items: center;
}

.ItemContainer i {
  margin-right: 1rem;
}

.ItemContainer .RemoveIcon {
  font-size: 0.8rem;
  color: #f00;
}

.ItemContainer .RemoveIcon + p {
  font-size: 1rem;
  color: #f00;
}

.ItemContainer:hover .RemoveIcon {
  font-size: 1rem;
}

.ItemContainer p {
  user-select: none;
}

@media print {
  .QuickAdd {
    display: none;
  }
}