.shopping-list {
  border-collapse: collapse;
  display: table;
  width: calc(100% + 30px);
  margin-left: -30px;
  user-select: none;
}

.shopping-list th {
  text-align: start;
  text-transform: uppercase;
  color: #798B95;
  font-weight: 300;
}

.shopping-list thead .shopping-list-header,
.shopping-list tbody #shopping-list-aisle,
.shopping-list tbody #shopping-list-level {
  text-align: center;
}

.shopping-list tbody tr.shopping-list-focus {
  background-color: #FBEFD3;
  -webkit-box-shadow: 0 0 3px #000;
  box-shadow: 0 0 3px #000;
}

.shopping-list tbody tr td {
  padding: 0.5rem 0;
  cursor: pointer;
  position: relative;
}

.shopping-list tbody tr td.shopping-list-strikeout::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  border-bottom: 2px solid #111;
  width: 100%;
}

.shopping-list tbody tr td i {
  font-size: 18px;
}

/* Number Bullets */
.shopping-list tbody td:nth-child(1) > span {
  width: 20px;
  height: 20px;
  background-color: #1B3E59;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  margin: 0 5px;
}

/* Item Name */
.shopping-list tbody td:nth-child(2) { max-width: 125px; }

/* Aisle Number */
.shopping-list tbody #shopping-list-aisle { max-width: 75px; }

/* Category Color */
.shopping-list tbody #shopping-list-category > span {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
}

/* Trash */
.shopping-list tbody #shopping-list-trash {
  color: #f00;
}

.fas.fa-check-circle {
  color: #00831c;
}

@media (max-device-width: 767px) {
  .shopping-list {
    margin: 0;
    width: 100%;
    margin-top: 2em;
    font-size: 13px;
    table-layout: fixed;
    display: block;
  }

  .shopping-list thead {
    margin-bottom: 0.5rem;
  }

  .shopping-list tbody {
    display: inline-block;
    overflow: scroll;
    box-sizing: border-box;
  }

  .shopping-list tbody::after {
    content: '';
    display: block;
    height: 150px;
  }

  .shopping-list thead,
  .shopping-list tbody tr {
    display:table;
    width:100vw;
    table-layout:fixed;
  }
}