.MyList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.MyList p {
  font-style: italic;
  margin: 3rem 0 4rem 0;
  text-align: center;
}

.MyList a {
  text-decoration: none;
  background-color: var(--primary-blue);
  color: var(--white) !important;
  padding: 1rem 2rem;
  border-radius: 10px;
  box-sizing: border-box;
  font-weight: 300;
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
}

.MyList a:hover {
  background-color: var(--secondary-blue);
}

@media (max-width: 768px) {
  .MyList .LoginList {
    position: absolute;
    top: 30%;
    font-size: 1.2em;
  }

  .MyList a {
    position: absolute;
    bottom: 100px;
    width: 90%;
  }
}