.LocationContainer {
	display: flex;
	justify-content: space-between;
	line-height: normal;
}

.LocationDetails {
	display: flex;
	flex-direction: column;
	justify-content: center;
}
  
.LocationDetails p {
	color: #808080;
	font-size: 0.9rem;
	opacity: 0.9;
}

@media only screen and (max-width: 767px) {
	.LocationContainer {
		line-height: 1.4rem;
	}

	.LocationDetails h4 {
		font-size: 18px;
	}

	.LocationDetails p {
		font-size: 1rem;
	}
}