.SortOption {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.SortOption > span {
  margin-right: 4px;
}

.SortOption select {
  padding: 5px;
  appearance: none;
  border: 1px #333 solid;
  font-size: 14px;
  padding: 5px 20px 5px 10px;
  outline: none;
  border-radius: 3px;
}

.SortOption div {
  position: relative;
}

.SortOption div span {
  position: absolute;
  height: 100%;
  width: 20px;
  top: 0;
  right: 0;
  box-sizing: border-box;
  pointer-events: none;
}

.SortOption div span i {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 767px) {
  .SortOption {
    justify-content: center;
  }
}