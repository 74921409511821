.TypeAhead {
  margin: 0 !important;
  width: 100%;
  position: relative;
}

.TypeAhead input {
  width: 175px;
  padding: 2.5px 5px;
  box-sizing: border-box;
  outline-color: var(--primary-blue);
  /* background-color: #66db66 !important; */
}

.TypeAhead ul {
  list-style-type: none;
  text-align: left;
  margin: 0;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.3);
  position: absolute;
  background: var(--white);
  z-index: 10;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  width: 175px;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 200px;
  top: 40px;
}

.TypeAhead li{
  padding: 8px 10px;
  cursor: pointer;
  font-size: 0.8rem;
}
  
.TypeAhead li:hover{
  background-color: #f1f1f1;
}

.Active {
  background-color: #f1f1f1;
}