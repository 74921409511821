.ExploreCreate button::before {
  content: 'x';
  color: var(--black);
  font-weight: 300;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 1.5rem;
  margin-right: 0.7rem;
}

.ExploreCreate h3 {
  margin: 1rem 0;
  font-weight: 500;
  font-size: 22px;
}

.ExploreCreate .ListContainer {
  width: 30%;
  min-width: 300px;
  background-color: var(--white);
  height: auto;
  padding: 1.5rem;
  margin: 2rem 0;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0,0,0,0.12);
}

@media (max-width: 767px) {
  .ExploreCreate .ListContainer {
    width: 100%;
    min-width: auto;
  }
}