.Trip {
  height: 600px;
}

.Block {
  display: block;
}

.Hide {
  display: none;
}

.Selected {
  font-weight: 700;
  color: #026AA4;
}

.Selected::before {
  transform: rotate(90deg);
}

.ProvBox {
  background: #d1cfcf;
  height: 550px;
  border: 1px #acacac solid;
  overflow: scroll;
  cursor: pointer;
  user-select: none;
}

.ProvBox li:hover {
  background: #e6f6fd;
}

.ProvBox li {
  list-style: none;
}

.ProvBox li div {
  padding: 15px 0 15px 25px;
}

.ProvBox div::before {
  content: "";
  border-color: transparent #111;
  border-style: solid;
  border-width: 0.30em 0 0.30em 0.30em;
  display: block;
  height: 0;
  width: 0;
  left: -13px;
  top: 12px;
  position: relative;
  transition: transform 0.3s;
}

.ProvBox div::before.selected {
  transform: rotate(90deg);
}

.CityBox {
  background: #e5e4e4;
}

.CityBox li div {
  padding: 15px 0 15px 50px;
}

.BrandBox {
  background: #eee;
}

.BrandBox li div {
  padding: 15px 0 15px 75px;
}

.StoreBox {
  background:#fafafa;
}

.StoreBox li div {
  padding: 15px 0 15px 100px;
}