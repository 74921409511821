.ForgetPassword {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.ContentContainer {
  border: 2px var(--primary-blue) solid;
  padding: 2rem;
  background-color: #fff;
  width: 500px;
}

.ContentContainer h2 {
  color: var(--primary-blue);
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
}

.ContentContainer form {
  margin: 1rem 0;
}

.ContentContainer form input {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: var(--white);
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  box-sizing: border-box;
  margin-bottom: 1rem;
  outline: none;
}

.ContentContainer form input:focus {
  outline: none;
  border: 2px solid var(--primary-blue);
}

.ContentContainer form button,
.ContentContainer a {
  display: inline-block;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 6px 12px;
  border-radius: 4px;
  color: var(--white);
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);
}

@media only screen and (max-width: 767px) {
  .ForgetPassword {
    justify-content: flex-start;
    margin-top: 3rem;
  }

  .ContentContainer {
    width: 90%;
    border: none;
  }

  .ContentContainer h2 {
    font-size: 1.8rem;
  }

  .ContentContainer p {
    font-size: 1.2rem;
  }


  .ContentContainer form button,
  .ContentContainer form input {
    font-size: 1rem;
  }

  .ContentContainer form button {
    width: 100%;
    border: 1px #eee solid;
    padding: 0.5rem 0;
  }
}