.StoreItem {
  padding: 5rem 3rem;
  width: 550px;
  border-right: 1px solid #efefef;
  box-sizing: border-box;
}

.StoreItem .Header {
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
}

.StoreItem .Header button {
  margin: 0 0.3rem 0 0;
  border-radius: 10px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 300;
  color: #fff;
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
  cursor: pointer;
  height: 50px;
  width: 120px;
}

.StoreItem .Header .AddTrip {
  background-color: var(--primary-blue)
}

.StoreItem .Header .AddTrip:hover {
  background-color: var(--secondary-blue)
}

.StoreItem .Header .UpdateTrip {
  background-color: #17b617;
}

.StoreItem .Header .UpdateTrip:hover {
  background-color: #43ac6a;
}

.StoreItem h3{
  margin-bottom: 1rem;
}

.StoreItem h5 {
  font-weight: normal;
  font-size: 0.9rem;
  color: var(--dark-grey);
  letter-spacing: normal;
}

.StoreItem form {
  margin-bottom: 2rem;
}

/* @media(max-width: 1400px){
  .StoreItem .Header button {
    padding: 1rem 1rem;
  }
} */

@media (max-device-width: 767px) {
  .Report {
    position: absolute;
    bottom: 110px;
    z-index: 1000;
    right: 10px;
    background: #026AA4;
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .Report i {
    font-size: 25px;
    position: absolute;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

@media print{
  .StoreItem {
    width: 100%;
    order: 2;
  }

  .StoreItem a,
  .StoreItem button {
    display: none;
  }
}