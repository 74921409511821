.ProjectHeader {
  display: flex;
  margin-top: 1rem;
  width: 100%;
}

@media (max-width: 767px) {
  .ProjectHeader {
    flex-direction: column;
  }
}