.ListContainer {
  display: flex;
  justify-content: space-between;
  flex-basis: 100%;
  align-items: center;
  
}

.ListContainer i {
  font-size: 22px;
}

.ListContainer p {
  font-size: 19px;
}