.ExploreHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
}

.ExploreHeader h1 {
  font-size: 22px;
  font-weight: 500;
}

.ExploreHeader a {
  background-color: var(--primary-blue);
  color: var(--white) !important;
  padding: 0.9rem 1.5rem;
  border-radius: 10px;
  box-sizing: border-box;
  font-weight: 300;
  text-align: center;
  transition: all 0.2s;
  outline: none;
  border: none;
  font-size: 16px;
  text-decoration: none;
}

.ExploreHeader a:active {
  transform: scale(0.9, 0.9);
}

.ExploreHeader a:hover {
  background-color: var(--secondary-blue);
}

@media (max-width: 767px) {
  .ExploreHeader {
    margin-bottom: 1.5rem;
    align-items: flex-start;
  }

  .ExploreHeader a {
    display: block;
    background-color: #fafafa;
    color: var(--primary-blue) !important;
    padding: 0 1.5rem;
    margin-top: -12px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .ExploreHeader a p:first-child {
    font-size: 30px;
  }
}