.ExploreTab {
  width: 100%;
  margin: 20px auto;
  columns: 3;
  /* column-gap: 40px; */
}

.ExploreTab .InfiniteScroll {
  overflow: visible!important;
}

.ExploreTab .Hide {
  display: none;
}

.ExploreTab .Show {
  display: block;
}

.Project{
  width: 100%;
  display: inline-block;
  margin: 0 0 20px;
  padding: 4px;
  overflow: hidden;
  break-inside: avoid;
  background-color: var(--white);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  border-radius: 10px;
  position: relative;
  /* z-index: 2; */
  box-sizing: border-box;
}

.Project img {
  max-width: 100%;
  display: block;
  margin: 0 auto;
}

.Project:hover {
  /* border: 1px solid #346BA4; */
  opacity: 0.93;
}

.Project > div .SaveBox {
  position: absolute;
  /* z-index: 10; */
  background-color: var(--primary-blue);
  border-radius: 10px;
  text-align: center;
  padding: 10px 15px;
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
}

.Project > div .SaveBox:hover,
.Project > div .EditBox:hover {
  background-color: var(--secondary-blue);
  opacity: 1 !important;
}

.Project > div .SaveBox i {
  margin-right: 8px;
}

.Project > div .SaveBox p {
  display: inline;
  font-weight: 300;
}

.Project > div .EditBox {
  position: absolute;
  left: 10px;
  top: 0;
  font-size: 20px;
  background-color: var(--primary-blue);
  padding: 10px;
  color: var(--white);
  border-radius: 50%;
}

.Project > div .ProjectMeta {
  padding: 10px 1rem;
}

.Project > div .ProjectMeta > p {
  margin: 14px 0 0;
  font-size: 16px;
  font-weight: 400;
}

.Project > div .ProjectMeta > div span {
  border-radius: 100px;
  border: 1px #031016 solid;
  color: #031016;
  padding: 3px 7px;
  margin-right: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  box-sizing: border-box;
}

@media (max-width: 1500px) {
  .ExploreTab {
    columns: 2;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .ExploreTab {
    columns: 1;
    padding: 0 1rem;
  }

  .Project p {
    font-size: 12px;
  }
}