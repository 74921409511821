.PostalCodeContainer {
  display: flex;
  flex-basis: 100%;
  margin-top: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.PostalCodeContainer input {
  padding: 1rem;
  width: 20%;
  background-color: #F1F1F1;
  border: 1px solid var(--dark-grey);
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  box-sizing: border-box;
  margin-right: 4rem;
}

.PostalCodeContainer input:focus {
  background-color: var(--white);
  outline: none;
  border: 2px solid var(--primary-blue);
}

.PostalCodeContainer p {
  text-transform: uppercase;
  color: var(--black) !important;
  margin-right: 4rem;
  font-size: 1.2rem !important;
  opacity: 1 !important;
}

.PostalCodeContainer a {
  text-decoration: none;
  color: var(--black);
}

.PostalCodeContainer .BtLoc {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid var(--dark-grey);
  background-color: #F1F1F1;
  padding: 1rem;
  color: var(--dark-grey);
  font-size: 1.1rem;
  text-transform: capitalize;
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
}

.PostalCodeContainer .BtLoc:active {
  background-color: var(--white);
  border: 1px solid var(--primary-blue);
}

.PostalCodeContainer .BtLoc i {
  padding-right: 0.5rem;
  color: var(--black);
  font-size: 1.3rem;
}

@media only screen and (max-width: 767px) {
  .PostalCodeContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .PostalCodeContainer input,
  .PostalCodeContainer .BtLoc {
    width: 100%;
    margin: 1rem auto;
  }

  .PostalCodeContainer p {
    margin: 0;
    width: 70%;
    text-align: center;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .PostalCodeContainer .BtLoc {
    font-size: 0.9rem;
  }
}