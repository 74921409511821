.TripsContainer {
  display: flex;
  justify-content: flex-start;
  padding: 1rem 0;
}

.MyTrip {
  width: 60%;
  min-width: 600px;
}

.TabsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Tabs {
  flex-basis: 50%;
  padding-bottom: 1rem;
  border-bottom: 2px solid #e8e8e8;
  text-align: center;
  outline: none;
}

.Tabs:hover {
  font-weight: bold;
  color: #346ba4;
}

/* Advertisement Styling  */
.AdContainer {
  width: 330px;
  height: 500px;
  min-width: 330px;
  min-height: 500px;
  margin-left: 5rem;
  border-radius: 15px;
  background-color: #fff;
  padding: 2rem;
  box-sizing: border-box;
  box-shadow: 0 1px 1px rgba(0,0,0,0.15), 
              0 2px 2px rgba(0,0,0,0.15), 
              0 4px 4px rgba(0,0,0,0.15), 
              0 8px 8px rgba(0,0,0,0.15);
  position: relative;
  display: flex;
  flex-direction: column;
  background: url('../../../img/mask-man.svg') 160% 70% no-repeat,
              url('../../../img/ellipse_11.svg') center right no-repeat;
  background-size: 250px 200px, 150px 150px;

}

.AdContainer .CloseButton {
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
}

.AdContainer .CloseButton:after{
  content: "\00d7"; /* This will render the 'X' */
  font-size: 2rem;
}

.AdContainer h3 {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 500;
  margin: 1rem 0;
}

.AdContainer p {
  color: var(--dark-grey);
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.AdContainer img {
  width: 140px;
  margin: 1rem 0;
}

@media(max-width: 1200px){
  
  .MyTrip {
    width: 40%;
  }
}

/* Mobile @media */
@media only screen and (max-width: 767px) {
  .TripsContainer .MyTrip {
    min-width: 100%;
  }

  .AdContainer {
    display: none;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .TripsContainer .MyTrip {
    min-width: 100%;
  }

  .AdContainer {
    display: none;
  }
}