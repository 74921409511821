.Modal{
  position: fixed;
  z-index: 500;
  background-color: var(--white);
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px black;
  box-sizing: border-box;
  transition: all 0.3s ease-out;
  min-height: auto;
  overflow-y: auto;
  width: 600px;
  top: 40px;
  left: calc(50% - 300px);
  transform: translateX(-50%);
}

.Backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

@media only screen and (max-width: 767px){
  .Modal {
    width: 90%;
    left: 5%;
  }
}