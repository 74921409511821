.Draggable {
  width: 100%;
  min-height: 100vh;
  cursor: pointer;
  background-color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px -3px 8px 0px rgba(156,156,156,0.59);
  padding: 10px;
  position: fixed;
  z-index: 20;
  touch-action: none;
  box-sizing: border-box;
}

.Draggable .DragDiv {
  height: 50px;
}

.Draggable .DragDiv div {
  height: 8px;
  border: 4px #eee solid;
  width: 75px;
  margin: 0 auto;
  border-radius: 8px;
}

@media only screen and (max-width: 767px){
  .Draggable form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
  }

  .Draggable form p {
    outline: none;
    border: none;
    width: 18%;
    text-align: center;
    color: #1C6EE7;
    font-size: 1.2rem;
    box-sizing: border-box;
    background-color: #fff;
  }
}