.ProjectComponents {
  margin: 1rem 0;
  width: 60%;
}

.ProjectComponents h4 {
  font-size: 16px;
  line-height: 19px;
  font-weight: 700;
  margin-bottom: 10px;
}

.ProjectComponents p {
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
}

@media (max-width: 1200px) {
  .ProjectComponents {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .ProjectComponents {
    width: 100%;
  }
}